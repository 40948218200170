/**
 * Created by MeePwn
 * https://github.com/maybewaityou
 *
 * description:
 *
 */
import { useState, useEffect } from 'react';
import { Base64 } from 'js-base64';
import { SelectedTab, dataTree } from '../../tab/data/dataPicture';
import { executePanoAction, getDataFromPano, reloadPanoXML, unlockStickersAndTextStickers, lockStickersAndTextStickers } from '../../panoramic/PanoramicView';
import { getPanoData, getResetData, setResetData, panoSize, setPanoData, removeTemplateData } from '../../panoramic/data';

import {
  ACTION_CHOOSE_ASPECT_1,
  ACTION_SHOW_BOX,
  ACTION_HIDE_BOX,
  STICKER_BOX_HIDE,
  textStickerAction,
  cruisingAction,
  topImageAction,
  hideTopImageAction,
  saveTopImageAction,
  stickerAction,
  chooseAspectAction,
  startBGM,
  stopBGM,
  saveBGM,
  getScreenshotURL,
  dynamicAction,
  noneDynamicAction,
  ACTION_CHANGE_FILTER,
  STICKER_BOX_SHOW,
} from '../../panoramic/actions';
import { fetchBackgroundMusicURL, getOriginImagePath, changeSkyTask, savePicture, fetchMaterialUsageUpdate, fetchModalUsedUpdate } from '../../../api/NetworkApi';
import {
  delay,
  getQueryString,
  getTitle,
  replaceUrl,
  panoRenderType,
  shouldChangeSky
} from '../../../utils/functional';
import { TemplateType } from '../../panoramic/template';
import { Wormhole, Destination } from '../../../vendor/wormhole/index';
import { processURL } from '../../../utils/data';
import { templateLoad, unChangeSky } from '../../float/mini-program/strategy/panoPicture/templatePictureLoad';


const tabModel = {
  selectedTab: '',
  hideTabFooter: false,
};
const textstickerData = {
  text: '',
}
// 天空预览模式-默认数据
const skyPreviewData = {
  sphereurl: '',
  sky: '',
}
// 是否点击贴纸
let stickerFlag = false;
export function useController(params?: any, initialState?: any) {
  const { panoConfig, onBGMItemClick, tipControl, setBGMItem } = params;
  const { initSelectedTab } = initialState;

  const [selectedTab, setSelectedTab] = useState(tabModel.selectedTab || initSelectedTab);
  const [hideTabFooter, setHideTabFooter] = useState(tabModel.hideTabFooter || false);

  if (!tabModel.selectedTab)
    tabModel.selectedTab = initSelectedTab;

  // 点击tab
  function handleTabClick(tag: SelectedTab) {
    console.log('tag :', tag);

    executePanoAction(ACTION_HIDE_BOX); // 裁剪隐藏弹框

    if (tag === SelectedTab.裁剪) {
      executePanoAction(ACTION_SHOW_BOX);
      executePanoAction(ACTION_CHOOSE_ASPECT_1);
    }
    if (tag === SelectedTab.贴纸) {
      // 设置贴纸可编辑模式
      unlockStickersAndTextStickers(tag);
      // 显示上一次编辑框
      executePanoAction(STICKER_BOX_SHOW);
    }
    if (tag === SelectedTab.标签) {
      // 设置标签可编辑模式
      // unlockStickersAndTextStickers(tag);
    }
    tabModel.selectedTab = tag;
    setSelectedTab(tag);
  }

  // 标签输入
  function handleChangeText(value: any) {
    textstickerData.text = value;
  }

  async function handleGroupTitleClick(tag: SelectedTab, group: any) {
    console.log(group);
  }

  // 拍照推送照片，使用模板点击方法
  async function handlePicPushTemplate(item: any = {}) {
    if (item.value) {
      const obj = {
        tipControl,
        hooks: {
          setBGMItem,
        }
      }
      templateLoad(item, obj);
    } else {
      setPanoData(getResetData());
      executePanoAction(stopBGM());
      reloadPanoXML();
    }
  }

  async function handleItemClick(item: any, tag: SelectedTab) {
    // TODO: 保存选中信息

    console.log(item, '-----');
    console.log(getResetData(), 'getResetData')
    console.log(getPanoData(), 'getPanoData')
    switch (tag) {
      case SelectedTab.模板:
        if (item.value) {
          const obj = {
            tipControl,
            hooks: {
              setBGMItem,
            }
          }
          templateLoad(item, obj);
        } else {
          const data: any = removeTemplateData('pic');
          // console.log(data, 'removeTemplateData')
          // 当前模版有换天
          if (shouldChangeSky()) {
            await unChangeSky(data, params);
          } else {
            data.scene[0].url = getPanoData().scene[0].url;
            setPanoData(removeTemplateData('pic'));
          }
          executePanoAction(stopBGM());
          reloadPanoXML();
        }
        return;
      case SelectedTab.动效:
        if (!item) {
          executePanoAction(noneDynamicAction());
          return;
        }
        executePanoAction(item.value);

        return
      case SelectedTab.滤镜:
        if (!item) {
          executePanoAction(ACTION_CHANGE_FILTER);
          return;
        }
        executePanoAction(item.value);
        return;
      case SelectedTab.配乐:
        if (!item) {
          onBGMItemClick(item);
          // getPanoData().music = '';
          getPanoData().bgmItem = {};
          return executePanoAction(stopBGM());
        }

        const [error, result] = await fetchBackgroundMusicURL(item.value);
        if (error) return tipControl.showToast(error);

        const resultData = JSON.parse(Base64.decode(result.data));
        const url = resultData.data.url;
        // getPanoData().music = url;
        const extraItem = { ...item, url };
        getPanoData().bgmItem = extraItem;
        executePanoAction(startBGM(url));

        onBGMItemClick(extraItem);
        return;
      case SelectedTab.巡航:
        console.log('item :', item);

        if (!item) {
          console.log('-=====-');

          executePanoAction(cruisingAction(0));
          return;
        }
        executePanoAction(cruisingAction(item.value));
        return;
      case SelectedTab.边框:
        // 更新 pano 样式, 显示全部贴纸
        // Wormhole.getInstance().enter(Destination.UPDATE_PANO_STYLE, panoSize.frame);
        if (!item) {
          executePanoAction(hideTopImageAction());
          return;
        }
        executePanoAction(topImageAction(item.value));
        executePanoAction(saveTopImageAction(item.value, item.id));

        // 隐藏 tab
        // tabModel.hideTabFooter = true;
        // setHideTabFooter(true);
        return;
      case SelectedTab.贴纸:
        executePanoAction(stickerAction(item.id, item.value));
        stickerFlag = true;
        return;
      case SelectedTab.裁剪:
        executePanoAction(ACTION_SHOW_BOX);
        executePanoAction(chooseAspectAction(item.flag));
        return;
      case SelectedTab.换天: {
        if (!item) {

          // sky: "sky-reset" - “清空操作标示”
          getPanoData().skyPreview = {
            sphereurl: "",
            sky: "sky-reset",
          };

          // 清除换天加载
          getPanoData().scene[0].sphereurl = '';
          // getPanoData().scene[0].sky = 'sky-reset';

          // 不存在换天
          if (panoRenderType() === TemplateType.cube) {
            await delay(200);
            return reloadPanoXML();
          }

          // 取消换天
          tipControl.showLoading();
          const [error, result] = await getOriginImagePath();
          tipControl.dismissLoading();
          if (error) return tipControl.showToast(error);

          getPanoData().scene[0].url = result;
          return reloadPanoXML();
        }

        tipControl.showLoading();
        const [error, result] = await changeSkyTask({
          id: getQueryString('orderId'),
          skyCode: item.value,
        });
        tipControl.dismissLoading();
        if (error) return tipControl.showToast(error);
        // getPanoData().scene[0].sphereurl = result.replace(/&/g, '&amp;');
        // getPanoData().scene[0].sphereurl = processURL(result);
        // getPanoData().scene[0].sky = item.value;

        // 换天预览模式
        getPanoData().skyPreview = {
          sphereurl: processURL(result),
          sky: item.value,
        }

        await delay(200);
        reloadPanoXML();
      }
        return;
      default:
        return;
    }
  }

  // 特效选中确定,更新resetData => 继续玩图操作
  function onConfirm(tag: SelectedTab) {
    Wormhole.getInstance().enter(Destination.UPDATE_PANO_STYLE, panoSize.tabButton);
    setSelectedTab('OTHERS');
    console.log(getPanoData(), 'getPanoData')
    switch (tag) {
      case SelectedTab.模板:
        setResetData(getPanoData());
        return;
      case SelectedTab.动效:
        setResetData(getPanoData());
        return;
      case SelectedTab.滤镜:
        setResetData(getPanoData());
        return;
      case SelectedTab.配乐:
        setResetData(getPanoData());
        return;
      case SelectedTab.巡航:
        setResetData(getPanoData());
        return;
      case SelectedTab.边框:
        setResetData(getPanoData());
        return;
      case SelectedTab.贴纸:
        // setResetData(getPanoData());
        // lockStickersAndTextStickers();

        // if (!stickerFlag) { // 没有点击过贴纸，不重新渲染
        //   executePanoAction(STICKER_BOX_HIDE);
        //   lockStickersAndTextStickers(tag); // 锁定编辑状态
        //   return;
        // }
        stickerFlag = false;
        getPanoData().stickerPreview = getPanoData().scene[0].sticker;
        reloadPanoXML();
        console.log(getPanoData())

        return;
      case SelectedTab.换天:
        getPanoData().scene[0].sphereurl = getPanoData().skyPreview.sphereurl;
        getPanoData().scene[0].sky = getPanoData().skyPreview.sky;

        getPanoData().skyPreview = { ...skyPreviewData }; // 清除预览模式数据
        return;
      case SelectedTab.标签:
        const data = dataTree[selectedTab];
        executePanoAction(textStickerAction(textstickerData.text, data.list[0]));
        textstickerData.text = '';
        return;
      default:
        return;
    }

  }

  // 特效取消“X”, 还原初始（作品/原片等）jsonData数据 => 继续玩图操作
  async function onCancel(tag: SelectedTab) {
    Wormhole.getInstance().enter(Destination.UPDATE_PANO_STYLE, panoSize.tabButton);
    setSelectedTab('OTHERS');
    console.log(getResetData(), 'getResetData')
    console.log(getPanoData(), 'getPanoData')
    const { snow, zfilter, bgmItem, autorotate, topimg } = getResetData();
    // const { scene, stickerPreview} = getPanoData();
    switch (tag) {
      case SelectedTab.模板:
        setPanoData(getResetData());
        executePanoAction(stopBGM()); // 暂停音乐
        reloadPanoXML();
        return;
      case SelectedTab.动效:
        const actionItem: any = {
          id: snow.id,
          imageurl: snow.imageurl,
          invert: snow.invert,
        }
        if (!actionItem.id) {
          executePanoAction(noneDynamicAction());
          return;
        }
        executePanoAction(dynamicAction(actionItem));
        return;
      case SelectedTab.滤镜:
        executePanoAction(`${zfilter.value}()`);
        return;
      case SelectedTab.配乐:
        getPanoData().bgmItem = { ...bgmItem };
        if (bgmItem.url) {
          executePanoAction(startBGM(bgmItem.url));
        } else {
          executePanoAction(stopBGM());
        }
        onBGMItemClick(bgmItem);
        return;
      case SelectedTab.巡航:
        executePanoAction(cruisingAction(autorotate.type));
        return;

      case SelectedTab.边框:
        if (!topimg.url) {
          executePanoAction(hideTopImageAction()); // 移除边框并保存数据
          return;
        }
        executePanoAction(topImageAction(topimg.url));
        executePanoAction(saveTopImageAction(topimg.url, topimg.id));
        return;
      case SelectedTab.贴纸:
        // 没有点击过贴纸  && 没有动过保存过的贴纸，不重新渲染
        // if (!stickerFlag && scene[0].sticker.length === stickerPreview.length) {
        //   executePanoAction(STICKER_BOX_HIDE);
        //   lockStickersAndTextStickers(tag); // 锁定编辑状态
        //   return;
        // }
        stickerFlag = false;
        // 还原贴纸数据
        getPanoData().scene[0].sticker = getPanoData().stickerPreview;
        console.log(getPanoData())
        await delay(200)
        reloadPanoXML();
        return;
      case SelectedTab.标签:
        return;
      case SelectedTab.换天:
        // skyPreview.sky 为 false 点‘X’不重新刷新
        if (!getPanoData().skyPreview.sky) {
          return;
        }

        getPanoData().skyPreview = { ...skyPreviewData }; // 清除预览模式

        // 还原天空
        if (!shouldChangeSky()) { // 不存在换天
          return reloadPanoXML();
        } else {
          tipControl.showLoading();
          const [error, result] = await changeSkyTask({
            id: getQueryString('orderId'),
            skyCode: getPanoData().scene[0].sky,
          });
          tipControl.dismissLoading();
          if (error) return tipControl.showToast(error);
          getPanoData().scene[0].sphereurl = processURL(result);
          await delay(200);
          reloadPanoXML();
        }
        return;
      default:
        return;
    }
  }

  // async function handleSave() {
  //   const id = getQueryString('orderId');
  //   const userWorksId = getQueryString('userWorksId');
  //   const title = getTitle();
  //   console.log(getPanoData(), 'getPanoData()-----');
  //   executePanoAction(ACTION_SCREENSHOT);
  //   await delay();
  //   const imageBase64Data = getDataFromPano(getScreenshotURL());
  //   const blob = await convertBase64UrlToBlob(imageBase64Data, 'image/jpeg');
  //   const thumbnailFile = await getPanoCroppedFile(blob);

  //   const params = {
  //     userProductId: id,
  //     userWorksName: title,
  //     typeCode: '01', // 01 全景图，02 普通图，03 视频
  //     userWorksId,
  //     data: Base64.encode(JSON.stringify(getPanoData())),
  //     photoUrl: getPanoData().scene[0].sphereurl || undefined,
  //     thumbnailFile,
  //     originalFlag: getPanoData().scene[0].sky === "sky-reset", // 后台重置换天url
  //   };
  //   // console.log(params);
  //   // console.log(getPanoData());
  //   // console.log(Base64.encode(JSON.stringify(getPanoData())));

  //   tipControl.showLoading();
  //   const [error, result] = await savePicture(params);
  //   tipControl.dismissLoading();
  //   if (error) return tipControl.showToast(error);

  //   const thumbnailUrl = (result && result.thumbnail_url) || '';
  //   const url = replaceUrl('data', encodeURIComponent(JSON.stringify(getPanoData())));
  //   const path = getQueryString('initvars.mypath');
  //   const stringifyUrl = JSON.stringify(`${url}&mypath=${path}&thumbnailUrl=${thumbnailUrl}`);

  //   // 模板使用量
  //   const templateId = getQueryString('templateId');
  //   if (templateId) {
  //     fetchModalUsedUpdate(templateId);
  //   }

  //   //更新素材使用量
  //   tipControl.showLoading();
  //   await fetchMaterialUsageUpdate(getPanoData());
  //   tipControl.dismissLoading();
  //   // 跳转到保存成功界面
  //   (window as any).wx.miniProgram.reLaunch({
  //     url: `/pagesA/meitu/save?url=${stringifyUrl}&type='01'`,
  //   });
  // }

  return {
    panoConfig,
    selectedTab,
    hideTabFooter,

    tipControl,
    handleChangeText,
    handleTabClick,
    handleGroupTitleClick,
    handleItemClick,
    // handleSave,
    onCancel,
    onConfirm,
    handlePicPushTemplate,
  };
}
