/**
 * Created by MeePwn
 * https://github.com/maybewaityou
 *
 * description:
 *
 */
import { getPanoData } from '../components/panoramic/data';
import { TemplateType } from '../components/panoramic/template';
import { processURL } from './data';

export async function to<T>(promise: Promise<T>): Promise<any[]> {
  return promise.then((result: T) => [null, result]).catch((err: Error) => [err, undefined]);
}

export function delay(timeout = 1000) {
  return new Promise((resolve) => setTimeout(resolve, timeout));
}

export function isEmpty(str: string) {
  return str === '' || str === undefined || str === null;
}

export function isNotEmpty(str: string) {
  return !isEmpty(str);
}

export function getQueryString(name: string) {
  const reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
  const URL = decodeURI(window.location.search);
  const r = URL.substr(1).match(reg);
  if (r != null) return unescape(r[2]);
  return '';
}

export function replaceUrl(key: string, value: string) {
  const paramsFromUrl = window.location.search.replace('?', '').substring(1);
  let url = `${processURL(window.location.href)}?`;
  const params = paramsFromUrl.split('&');
  params.forEach((item, index) => {
    if (index === 0) {
      if (item.indexOf(key) > -1) {
        url = url + `${key}=${value}`;
      } else {
        url = url + `${item}`;
      }
    } else {
      if (item.indexOf(key) > -1) {
        url = `${url}&${key}=${value}`;
      } else {
        url = `${url}&${item}`;
      }
    }
  });
  return url;
}

export function getTitle() {

  const panoType = getQueryString('type');

  const titleFromUrl = getQueryString('title');
  const title = titleFromUrl
    ? decodeURIComponent(titleFromUrl)
    : panoType
      ? '小7全景'
      : '720全景拍';
  return title;
}

export function getSerialNumber(imgUrl: string) {

  return imgUrl.split('/')[imgUrl.split('/').length - 2];
}

export function shouldShow(type: string = getQueryString('type')) {
  return type && type === 'show';
}

/**
 * 从 "分享" 点击进入
 */
export function fromShare() {
  const value = getQueryString('editable');
  return value && value !== undefined;
}

/**
 * 转赠照片
 */
export function fromGiven() {
  const value = getQueryString('given');
  return value && value !== undefined;
}

export function shouldEdit(type: string = getQueryString('type')) {
  return type && type === 'edit';
}

export function shouldShowDetail(type: string = getQueryString('type')) {
  return type && type === 'detail';
}

export function shouldShowDefault(type: string = getQueryString('type')) {
  return type && type === 'default';
}

/**
 * 拍照推送
 */
export function shouldShowPush(type: string = getQueryString('type')) {
  return type && type === 'panoPush';
}

/**
 * 换天
 */
export function shouldChangeSky() {
  const sky = getPanoData().scene[0].sky;
  return sky && sky !== 'sky-reset';
}

export function isTemplate(templateId: string = getQueryString('templateId')) {
  return templateId !== '';
}

/**
 * 玩同款
 */
export function isSameTemplate() {
  return shouldEdit() && isTemplate();
}

export function panoPicture() {
  const pictureType = getQueryString('pictureType');
  console.log('pictureType :', pictureType);

  return pictureType && pictureType === 'pano';
}

export function normalPicture() {
  const pictureType = getQueryString('pictureType');
  return pictureType && pictureType === 'normal';
}

export function paymentFlag() {
  const paymentFlag = getQueryString('payment')
  return paymentFlag
}

export function panoVideo() {
  const pictureType = getQueryString('pictureType');
  return pictureType && pictureType === 'panoVideo';
}

// 设备-展示景区宣传全景照片
export function devicePicture() {
  const url = window.location.href;
  return url.indexOf('spot-resource/') !== -1;
}

// 公众号全景照片
export function officialPicture() {
  const url = window.location.href;
  return url.indexOf('photos/') !== -1;
}

// 公众视频
export function officialVideo() {
  const url = window.location.href;
  return url.indexOf('panoramic-videos/') !== -1 || url.indexOf('panoramic-delay-videos/') !== -1;
}

/**
 * 是否为作品
 */
export function isUserWorks() {
  const userWorksId = getQueryString('userWorksId');
  return userWorksId !== '';
}

/**
 * 模板权限
 */
export function templateAuthority() {
  const templateFlag = getQueryString('templateFlag');
  return templateFlag === "true";
}

/**
 * 页面页脚样式
 */
export function footerLayout() {
  const footerLayout = getQueryString('footerLayout');
  return footerLayout;
}

/**
 * 全景图渲染方式
 */
export function panoRenderType(url: string = getQueryString('initvars.mypath')): TemplateType {
  if (url.indexOf('.png') > -1 || url.indexOf('.jpg') > -1 || url.indexOf('.jpeg') > -1) {
    return TemplateType.sphere;
  }
  return TemplateType.cube;
}
