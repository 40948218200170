/**
 * Created by MeePwn
 * https://github.com/maybewaityou
 *
 * description:
 *
 */

import { useState, useEffect } from "react";
import SizedBox from "../size-box/SizedBox";
import Checkbox from "../checkbox/Checkbox";
import { executePanoAction } from "../panoramic/PanoramicView";

import { Radio } from "./Radio";
// import RecordRTC from "recordrtc";
import { cruisingAction } from "../panoramic/actions";
import {
  ACTION_CHANGE_CRYSTAL_BALL,
  ACTION_CHANGE_SMALL_PLANET,
  ACTION_CHANGE_FISHEYE,
  ACTION_TOGGLE_GYRO,
  ACTION_SOUND_PLAY,
  ACTION_SOUND_PAUSE,
  ACTION_DOWN_LOAD,
  ACTION_MAKE_SCREENSHOT,
} from "../panoramic/actions";
import { Color } from '../../constant/Color';
import Dialog from "../../components/modal/Dialog";
import DialogCopy from "../../components/modal/DialogCopy";
import DialogImage from "../../components/modal/DialogImgae";
import { fetchRepeat, saveTheImage, miniVideo } from "../../api/NetworkApi";
import { getQueryString, delay } from "../../utils/functional";
import { SelectedTab, dataTree } from "../tab/data/dataPicture";
import { useController as footerViewController } from "../../../src/components/footer/controller/FooterViewController";
import Cruise from "./components/Cruise";
import WatchCruise from './components/WatchCruise'
import ScreenshotCruise from './components/ScreenshotCruise'
import AImodule from "./components/AImodule";
import { EmptyUserRewards, transcode } from "../../api/NetworkApi";
import Loading from "../loading/Loading";
import { Components } from "antd/es/date-picker/generatePicker";
import { onSound3D } from '../panoramic/actions'
import html2canvas from 'html2canvas'
const { aplus_queue }: any = window;
// import { dataTree } from "../tab/data/dataPicture";
export function RadioGroupPicture(props: any) {
  const { orderData } = props;
  const { onPhotograph, checkRefund } = footerViewController(props);
  const deviceAppCode = props.deviceAppCode.indexOf("app_code_3");
  const deviceAppCode1 = props.deviceAppCode.indexOf("admin");
  const objectFlag = getQueryString("objectFlag");
  // console.log('objectFlag :', objectFlag);

  const given = getQueryString("given");
  const { krpano } = window as any;
  const [title, setTitle] = useState("");
  const [portTitle, setPortTitle] = useState('')
  const [protFlag, setProtFlag] = useState(false)
  const [items, setItem] = useState<any>("");
  const [cruiseShow, setCruiseShow] = useState(false);
  const [AIitem, setAIitem] = useState<any>("");
  const [AIShow, setAIShow] = useState(false);
  const [AIData, setAIData] = useState<any>([]);
  const miniProgram = (window as any).wx.miniProgram;
  const [loadingShow, setLoadingShow] = useState(false)
  const [checkFlag, setCheckFlag] = useState(false)
  const [newTag, setNewTaf] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalUrl, setModalUrl]: any = useState('')
  const [saveFlag, setSaveFlag] = useState('ios')
  const [guidePage, setGuidePage] = useState(false)
  const [maskNum, setMaskNum] = useState(1)
  const userProductId = getQueryString('orderId');
  // const new_dataTree:any = dataTree['巡航'][0]
  const [initialView, setInitialView] = useState(0)
  const [imageUrl, setImageUrl] = useState('')
  const [imageFlag, setImageFlag] = useState(false)
  const [radioState, setRadioState] = useState({
    crystalball: false,
    smallplanet: false,
    fisheye: false,
    MakeScreenshot: false,
    MakeHiResScreenshot: false,
    music: false,
    videoFn: false,
    AI: false,
    watchFn: false,
    screenshotFn: false,
    screenshotsmallplanet: false,
    gyro: false,
    Smallvideotravelwindbrush: false,
    Variablespeedfocusing: false
  });


  const [watchShow, setWatchShow] = useState(false)
  const [screenshotShow, setScreenshotShow] = useState(false)
  const uni = (window as any).uni;
  useEffect(() => {
    setRadioState({
      crystalball: false,
      smallplanet: props.selected,
      fisheye: false,
      MakeScreenshot: false,
      MakeHiResScreenshot: false,
      music: false,
      videoFn: false,
      AI: false,
      watchFn: false,
      screenshotFn: false,
      screenshotsmallplanet: false,
      gyro: false,
      Smallvideotravelwindbrush: false,
      Variablespeedfocusing: false
    });
  }, [props]);
  // 水晶球、平面、全景、小行星、陀螺仪
  const watchData = [
    {
      title: '水晶球',
      icon: 'img/miniProgramIcon/ic-crystalball-unchecked.png',
      highlightIcon: 'img/miniProgramIcon/ic-crystalball.png',
      tag: 'crystalball'
    }, {
      title: '正常',
      icon: 'img/miniProgramIcon/ic-fisheye-unchecked.png',
      highlightIcon: 'img/miniProgramIcon/ic-fisheye.png',
      tag: 'fisheye'
    }, {
      title: '小行星',
      icon: 'img/miniProgramIcon/ic-smallplanet-unchecked.png',
      highlightIcon: 'img/miniProgramIcon/ic-smallplanet.png',
      tag: 'smallplanet'
    }, {
      title: '陀螺仪',
      icon: 'img/miniProgramIcon/ic-gyro-unchecked.png',
      highlightIcon: 'img/miniProgramIcon/ic-gyro.png',
      tag: 'gyro'
    },
    // , {
    //   title: '小行星',
    //   icon: 'img/radio/ic-smallplanet-unchecked.png',
    //   highlightIcon: 'img/radio/ic-smallplanet.png'
    // }
  ]
  // 横屏、竖屏、小行星
  const screenshotData = [
    {
      title: '横屏',
      icon: 'img/miniProgramIcon/scenery.png',
      highlightIcon: 'img/miniProgramIcon/scenery.png',
      tag: 'MakeHiResScreenshot'
    }, {
      title: '竖屏',
      icon: 'img/miniProgramIcon/SelectedPortrait.png',
      highlightIcon: 'img/miniProgramIcon/SelectedPortrait.png',
      tag: 'MakeScreenshot'
    }
    // , {
    //   title: '小行星',
    //   icon: 'img/radio/ic-smallplanet-unchecked.png',
    //   highlightIcon: 'img/radio/ic-smallplanet.png',
    //   tag: 'screenshotsmallplanet'
    // }
  ]
  // 跳转3D渲染页面
  function render3D() {
    const orderId = getQueryString("orderId");
    // 跳转3D渲染页面
    miniProgram.navigateTo({
      url: `/pagesA/render3D/index?userProductId=${orderId}&type=orderEdit`,
    });
  }
  // 友盟数据上报
  const uma_data_report = (type: string, title?: string) => {
    aplus_queue.push({
      action: "aplus.record",
      arguments: ['photo_click', 'CLK', {
        'type': type,
        'title': title ? title : ''
      }],
    });
  }
  // 处理完成将base64图片路径传递到小程序 进行图片保存  截图
  const downLoadFunc = (url: any) => {
    // 关闭页面并返回消息
    // 保存图片不可退费
    saveTheImage(getQueryString("orderId"));
    miniProgram.postMessage({
      data: [{ type: "imgUrl", url }],
    });
    uni.reLaunch({ url: "/pages/index/index?page=page2" });
  };
  // 不同事件触发不同效果
  async function onSelectedChange(tag: string) {
    if (tag === "crystalball") {
      uma_data_report('切换视角', '水晶球')
      setWatchShow(false)
      // 水晶球
      executePanoAction(ACTION_CHANGE_CRYSTAL_BALL);
    } else if (tag === "smallplanet") {
      uma_data_report('切换视角', '小行星')
      setWatchShow(false)
      // 小行星
      executePanoAction(ACTION_CHANGE_SMALL_PLANET);
    } else if (tag === "fisheye") {
      uma_data_report('切换视角', '鱼眼')
      setWatchShow(false)
      // 鱼眼
      executePanoAction(ACTION_CHANGE_FISHEYE);
    } else if (tag === 'gyro') {
      uma_data_report('切换视角', '陀螺仪')
      // 陀螺仪
      handleCheckedChange()
      setWatchShow(false)
    } else if (tag === "videoFn") {
      // 视频
      setAIShow(false)
      // setCruiseShow(!cruiseShow)
      setScreenshotShow(false)
      setWatchShow(false)
      setNewTaf(tag);
      GeneratingVideos(dataTree["巡航"][0].list[0])
    } else if (tag === 'watchFn') {
      setCruiseShow(false)
      setScreenshotShow(false)
      setWatchShow(!watchShow)
    } else if (tag === 'screenshotFn') {
      setWatchShow(false)
      setCruiseShow(false)
      setScreenshotShow(!screenshotShow)
    } else if (tag === "AI") {
      setAIShow(true);
    } else if (tag === "MakeScreenshot" || "MakeHiResScreenshot") {
      setScreenshotShow(false)
      // setTitle("图片保存后不可退费");
      // 展示确认框
      // setIsModalOpen(true);
      if (tag === "MakeScreenshot") {
        uma_data_report('截图', '竖屏截图')
        // executePanoAction("choose_aspect(5)")
        executePanoAction(ACTION_DOWN_LOAD);
        setImageUrl(krpano.get("layer[screenshotimage].url"))
        setImageFlag(true)
        // downLoadFunc(krpano.get("layer[screenshotimage].url"));
      } else {
        uma_data_report('截图', '横屏截图')
        // executePanoAction("choose_aspect(4)")
        executePanoAction(ACTION_MAKE_SCREENSHOT);
        setImageUrl(krpano.get("layer[screenshotimage].url"))
        setImageFlag(true)
      }
      //下载
      setNewTaf(tag);
      // poster
      // 分享小行星图
    } else if (tag === 'screenshotsmallplanet') {
      setScreenshotShow(false)
      setTitle("图片保存后不可退费");
      setIsModalOpen(true);
      setNewTaf(tag);
    }
  }
  // 监听事件
  function handleSelectedChange(tag: string) {
    setCruiseShow(false);
    setAIShow(false);
    setScreenshotShow(false)
    setWatchShow(false)
    onSelectedChange(tag);
    if (tag !== "videoFn" && tag !== "AI" && tag !== 'watchFn' && tag !== 'screenshotFn') {
      setCruiseShow(false);
      setAIShow(false);
      setScreenshotShow(false)
      setWatchShow(false)
      setRadioState({
        crystalball: false,
        smallplanet: false,
        fisheye: false,
        MakeScreenshot: false,
        MakeHiResScreenshot: false,
        music: false,
        videoFn: false,
        AI: false,
        watchFn: false,
        screenshotFn: false,
        screenshotsmallplanet: false,
        gyro: false,
        Smallvideotravelwindbrush: false,
        Variablespeedfocusing: false,
        [tag]: true,

      });
    } else {
      setRadioState({
        crystalball: false,
        smallplanet: false,
        fisheye: false,
        MakeScreenshot: false,
        MakeHiResScreenshot: false,
        music: false,
        videoFn: false,
        AI: false,
        watchFn: false,
        screenshotFn: false,
        screenshotsmallplanet: false,
        gyro: false,
        Smallvideotravelwindbrush: false,
        Variablespeedfocusing: false,
        [tag]: true,
      });
    }
  }
  // 开启/关闭全景声
  function handleSound(check: boolean) {
    uma_data_report('声音开关', '声音')
    if (check) {
      if (checkFlag) executePanoAction(ACTION_SOUND_PLAY);
      else {
        delay(500).then(onSound3D);
        setCheckFlag(true)
      }
    } else {
      executePanoAction(ACTION_SOUND_PAUSE);
    }
  }

  const AISpecialEffects = (item: any) => {
    setAIitem(item);
    // setTitle(`是否确定使用${item}`)
    setAIShow(false);
    // setIsModalOpen(true)
  };
  // 图片添加运动轨迹
  const trajectoryMovement = (item: any) => {
    if (item.value === 6 || item.value === 7) {
      setItem(item);
      setTitle(`是否确定使用${item.title}`);
      setCruiseShow(false);
      setIsModalOpen(true);
    } else {
      setItem(item);
      setTitle(`是否确定使用${item.title}`);
      setCruiseShow(false);
      setIsModalOpen(true);
    }
  };


  const GeneratingVideos = (items: any) => {
    // 设置初始视角
    setMainView();
    executePanoAction(cruisingAction(items.value));
    // 设置巡航动作以及巡航速度
    krpano.set("autorotate.speed", 25);
    krpano.set("autorotate.enabled", true);
    PictureAnimation(true);

    // 保存当前的视角
    let initialView = krpano.get("view.hlookat");
    // 定义旋转结束标志和状态检查标志
    // 定义检查自动旋转状态的函数
    const checkRotationStatus = () => {
      let currentView = krpano.get("view.hlookat");
      // 计算旋转一圈的角度范围
      let scope = 360;
      // 检查是否旋转了一圈
      if (Math.abs(currentView - initialView) > scope) {
        // 停止巡航
        krpano.set("autorotate.enabled", false);
        clearInterval(rotationCheckInterval); // 停止定时器
        setCruiseShow(false);
        checkRotationStatusFlag = true; // 标记旋转已完成
      }
    }
    // 每隔一段时间检查一次自动旋转状态
    let rotationCheckInterval = setInterval(checkRotationStatus, 100); // 每100毫秒检查一次
    // executePanoAction(cruisingAction(0));
  }

  // 轮循请求
  const miniVideoFn = (params: any) => {
    let time: any = null;
    setLoadingShow(true)
    miniVideo(params).then((res: any) => {
      if (res[1].errMsg.indexOf('当前访问人数较多') > 0) {
        setLoadingShow(false)
        setProtFlag(true)
        setPortTitle('当前访问人数较多，请稍后重试!');
      } else if (res[1].fileUrl != '') {
        setModalUrl(res[1].fileUrl)
        setLoadingShow(false)
        setLoadingShow(false)
        // 视频录制完成之后 模态框展示渲染完成视频
        setModalOpen(true)
      } else {
        // 轮询请求
        time = setInterval(() => {
          clearInterval(time);
          miniVideoFn(params)
        }, 3000);
      }
    })
  }
  let checkRotationStatusFlag = false
  // 模态框点击确定
  const handleOk = () => {
    if (newTag === "videoFn") {
      uma_data_report('生成视频', items.title)
      console.log('items :', items);
      if (!items) {
        // 设置初始视角
        setMainView();
        executePanoAction(cruisingAction(items.value));
        // 设置巡航动作以及巡航速度
        krpano.set("autorotate.speed", 25);
        krpano.set("autorotate.enabled", true);
        PictureAnimation(true);

        // 保存当前的视角
        let initialView = krpano.get("view.hlookat");
        // 定义旋转结束标志和状态检查标志
        // 定义检查自动旋转状态的函数
        const checkRotationStatus = () => {
          let currentView = krpano.get("view.hlookat");
          // 计算旋转一圈的角度范围
          let scope = 360;
          // 检查是否旋转了一圈
          if (Math.abs(currentView - initialView) > scope) {
            // 停止巡航
            krpano.set("autorotate.enabled", false);
            clearInterval(rotationCheckInterval); // 停止定时器
            setCruiseShow(false);
            checkRotationStatusFlag = true; // 标记旋转已完成
          }
        }
        // 每隔一段时间检查一次自动旋转状态
        let rotationCheckInterval = setInterval(checkRotationStatus, 100); // 每100毫秒检查一次
        executePanoAction(cruisingAction(0));
      } else if (items.value == 6 || items.value == 7) {
        if (/iPad|iPhone|iPod|Mac/.test(navigator.userAgent)) {
          setSaveFlag('ios')
        } else {
          setSaveFlag('android')
        }
        const params = {
          user_product_id: userProductId,
          command: "templateVideo",
          file_type: items.value === 6 ? 1 : 2
        }
        miniVideoFn(params)
      }
    } else if (newTag === 'screenshotsmallplanet') {
      uni.reLaunch({ url: "/pages/index/index?page=page2" });
      miniProgram.postMessage({
        data: [{ type: "poster" }],
      });
    }
    setIsModalOpen(false);
  };
  // 设置视角和视场角
  function setMainView() {
    const mainPan: number = 45; // 设置的水平视角
    const mainTilt: number = 0; // 设置的垂直视角
    const mainFov: number = 90; // 设置的视场角
    krpano.set('view.hlookat', mainPan);
    krpano.set('view.vlookat', mainTilt);
    krpano.set('view.fov', mainFov);
  }
  // 模态框点击取消
  const handleCancel = () => {
    setIsModalOpen(false);
    setCruiseShow(false);
    PictureAnimation(false);
    setLoadingShow(false)
  };
  const protHandleOk = () => {
    setProtFlag(false);
  }
  const protHandleCancel = () => {
    setProtFlag(false);
  }




  // 替换 URL-safe Base64 字符
  const base64ToBlob = (base64: any, mimeType: string) => {
    // 替换 URL-safe Base64 字符
    const base64Clean = base64.replace(/-/g, '+').replace(/_/g, '/');

    // 计算需要填充的字符
    const padding = '='.repeat((4 - base64Clean.length % 4) % 4);
    const base64WithPadding = base64Clean + padding;

    // 解码 Base64 字符串
    const byteChars = atob(base64WithPadding);
    const byteArrays = [];

    for (let offset = 0; offset < byteChars.length; offset += 512) {
      const slice = byteChars.slice(offset, offset + 512);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: mimeType });
  }
  // 处理base64
  const extractBase64DataUrl = (base64DataUrl: string): string => {
    const commaIndex = base64DataUrl.indexOf(',');
    if (commaIndex === -1) {
      throw new Error("Invalid data URL format");
    }
    return base64DataUrl.substring(commaIndex + 1);
  }
  // 录制视频内容
  const newPictureAnimation = async (bool: boolean) => {
    const frameRate = 30;
    const duration = 3; // 3秒 
    const frames: Blob[] = [];
    // const startTime = Date.now();
    let startTime = 0
    function captureFrame() {
      executePanoAction(ACTION_DOWN_LOAD);
      console.log('krpano.get("layer[screenshotimage].url") :', krpano.get("layer[screenshotimage].url"));

      const base64 = (extractBase64DataUrl(krpano.get("layer[screenshotimage].url")))
      const blob = base64ToBlob(base64, 'image/png');
      const url = URL.createObjectURL(blob);
      console.log('url :', url);
      frames.push(blob);
      if (startTime < duration * 1000) {
        startTime++
        setTimeout(captureFrame, 10);
      } else {
        console.log('Captured frames count:', frames);
        PictureToVideo(frames, frameRate, duration);
      }
    }

    captureFrame();
  };
  // 判断是否为有效的 HTMLImageElement
  const isValidImage = (image: any): image is HTMLImageElement => {
    return image instanceof HTMLImageElement && image.complete && image.naturalWidth > 0;
  }
  // 从 Blob 创建 HTMLImageElement
  const createImageElementFromBlob = (blob: Blob): Promise<HTMLImageElement> => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      const url = URL.createObjectURL(blob);
      img.onload = () => {
        URL.revokeObjectURL(url); // 清理内存
        resolve(img);
      };
      img.onerror = (error) => {
        URL.revokeObjectURL(url); // 清理内存
        reject(error);
      };
      img.src = url;
    });
  }
  // 将图片帧数组转换为视频
  const PictureToVideo = async (blobData: Blob[], frameRate: number, duration: number) => {
    // 创建一个隐藏的 canvas 元素
    const hiddenCanvas = document.createElement('canvas');
    hiddenCanvas.style.display = 'none'; // 隐藏 canvas
    document.body.appendChild(hiddenCanvas);
    const ctx: any = hiddenCanvas.getContext('2d');
    if (!ctx) {
      console.error('Failed to get canvas context');
      return;
    }

    // 设置 canvas 尺寸
    hiddenCanvas.width = 1920;
    hiddenCanvas.height = 1080;

    // 捕获 canvas 的流
    const stream = hiddenCanvas.captureStream(frameRate);
    const formats = [
      { mimeType: 'video/webm; codecs="vp9"', videoBitsPerSecond: 2500000 },
      { mimeType: 'video/mp4; codecs="avc1.42E01E"', videoBitsPerSecond: 2500000 },
      { mimeType: 'video/webm; codecs="vp8"', videoBitsPerSecond: 2500000 }
    ];

    let selectedFormat: any = null;
    for (const format of formats) {
      if (MediaRecorder.isTypeSupported(format.mimeType)) {
        selectedFormat = format;
        break;
      }
    }
    const mediaRecorder = new MediaRecorder(stream, selectedFormat);
    const chunks: Blob[] = [];

    mediaRecorder.ondataavailable = (event) => {
      if (event.data.size > 0) {
        chunks.push(event.data);
      }
    };

    mediaRecorder.onstop = () => {
      const videoBlob = new Blob(chunks, { type: 'video/webm' });
      const url = URL.createObjectURL(videoBlob);
      console.log('Video recording complete. Video URL:', url);
      setModalUrl(url);
      setLoadingShow(false);
      setModalOpen(true);
      document.body.removeChild(hiddenCanvas);
    };

    let frameIndex = 0;

    function renderFrame() {
      if (frameIndex < blobData.length) {
        const blob = blobData[frameIndex];
        createImageElementFromBlob(blob)
          .then(image => {
            if (isValidImage(image)) {
              console.log('Drawing image at frame:', frameIndex);
              ctx.clearRect(0, 0, hiddenCanvas.width, hiddenCanvas.height);
              ctx.drawImage(image, 0, 0, hiddenCanvas.width, hiddenCanvas.height);
              frameIndex++;
              setTimeout(renderFrame, 1000 / frameRate);
            } else {
              console.error('Invalid image frame at index:', frameIndex);
              frameIndex++;
              setTimeout(renderFrame, 1000 / frameRate);
            }
          })
          .catch(error => {
            console.error('Error creating image element:', error);
            frameIndex++;
            setTimeout(renderFrame, 1000 / frameRate);
          });
      } else {
        console.log('Stopping media recorder');
        mediaRecorder.stop();
      }
    }

    mediaRecorder.start();
    setTimeout(() => {
      if (frameIndex < blobData.length) {
        mediaRecorder.stop();
        console.log('Recording stopped due to duration limit.');
      }
    }, duration * 1000);

    renderFrame();
  };
  // 转码完成
  const reRenderAndRecordVideo = (blob: Blob, canvasWidth: number = 1024, canvasHeight: number = 2048) => {
    console.log("开始执行 reRenderAndRecordVideo 函数");

    const newCanvas = document.createElement('canvas');
    const ctx = newCanvas.getContext('2d');
    newCanvas.width = canvasWidth;
    newCanvas.height = canvasHeight;
    console.log(`创建画布，宽度: ${canvasWidth}，高度: ${canvasHeight}`);

    const video = document.createElement('video');
    video.src = URL.createObjectURL(blob);
    console.log('video.src :', video.src);

    video.muted = true;
    video.preload = 'auto';
    video.autoplay = true; // 确保视频自动播放
    console.log("创建视频元素并设置源");

    video.oncanplay = () => {
      console.log("视频可以播放，尝试开始播放视频");

      video.play().then(() => {
        console.log("视频播放已启动");

        const stream = newCanvas.captureStream(30);
        console.log("画布流已捕获");

        let mediaRecorder = null;
        const formats = [
          { mimeType: 'video/webm; codecs="vp9"', videoBitsPerSecond: 2500000 },
          { mimeType: 'video/mp4; codecs="avc1.42E01E"', videoBitsPerSecond: 2500000 },
          { mimeType: 'video/webm; codecs="vp8"', videoBitsPerSecond: 2500000 }
        ];

        for (const format of formats) {
          if (MediaRecorder.isTypeSupported(format.mimeType)) {
            mediaRecorder = new MediaRecorder(stream, format);
            console.log(`使用格式初始化 MediaRecorder: ${format.mimeType}`);
            break;
          }
        }

        if (!mediaRecorder) {
          console.error('未找到支持的 MIME 类型用于 MediaRecorder');
          return;
        }

        mediaRecorder.ondataavailable = (event) => {
          if (event.data.size > 0) {
            console.log('MediaRecorder 有数据可用');
            const newBlob = new Blob([event.data], { type: 'video/webm' });
            const reader = new FileReader();
            reader.readAsDataURL(newBlob);
            reader.onload = () => {
              console.log('Blob 已读取为数据 URL');
              const base64 = reader.result as string;
              if (/iPad|iPhone|iPod|Mac/.test(navigator.userAgent)) {
                setModalUrl(base64)
                setSaveFlag('ios')
                setLoadingShow(false)
                // 视频录制完成之后 模态框展示渲染完成视频
                setModalOpen(true)
              } else {
                var formData = new FormData();
                formData.append('file', blob);
                transcode(formData).then((res: any) => {
                  setModalUrl(res[1])
                  setSaveFlag('android')
                  setLoadingShow(false)
                  // 视频录制完成之后 模态框展示渲染完成视频
                  setModalOpen(true)
                })
              }
              // 处理 base64 数据或上传
              console.log('录制完成，base64 数据:', base64);
            };
          }
        };

        mediaRecorder.onerror = (event: Event | any) => {
          console.error('MediaRecorder 错误:', event.error);
        };

        mediaRecorder.start();
        console.log("MediaRecorder 已启动");

        const drawFrame = () => {
          if (!video.paused && !video.ended) {
            ctx?.drawImage(video, 0, 0, newCanvas.width, newCanvas.height);
            requestAnimationFrame(drawFrame);
          }
        };

        drawFrame();
        console.log("从视频中绘制帧");

        video.addEventListener('ended', () => {
          console.log("视频结束，停止 MediaRecorder");
          mediaRecorder.stop();
        });
      }).catch((error) => {
        console.error('启动视频播放时出错:', error);
      });
    };

    video.onerror = (error) => {
      console.error('视频加载错误:', error);
    };

    console.log("设置完成，等待视频播放");
  };

  // 图片转视频 转码
  const PictureAnimation = (bool: any) => {
    const { krapno } = window as any;
    setLoadingShow(true);
    if (!bool) return;
    if (krapno) krapno.stopAll();

    // 检测 MediaRecorder 是否被支持
    if (typeof MediaRecorder === 'undefined') {
      console.log('浏览器不支持 MediaRecorder。请使用最新版本的浏览器。');
      setIsModalOpen(true);
      setTitle('浏览器不支持 MediaRecorder。请使用最新版本的浏览器。')
      setLoadingShow(false);
      return;
    }
    const canvas: any = document.querySelector("canvas");
    if (!canvas) {
      console.log('未找到 canvas 元素。');
      setLoadingShow(false);
      return;
    }
    const stream = canvas?.captureStream(30);
    if (!stream) {
      console.log('无法从 canvas 捕获视频流。');
      setLoadingShow(false);
      return;
    }
    // 检测 MediaRecorder 支持的格式
    const formats = [
      { mimeType: 'video/webm; codecs="vp9"', videoBitsPerSecond: 2500000 },
      { mimeType: 'video/mp4; codecs="avc1.42E01E"', videoBitsPerSecond: 2500000 },
      { mimeType: 'video/webm; codecs="vp8"', videoBitsPerSecond: 2500000 }
    ];
    let selectedFormat: any = null;
    for (const format of formats) {
      if (MediaRecorder.isTypeSupported(format.mimeType)) {
        selectedFormat = format;
        break;
      }
    }
    if (!selectedFormat) {
      console.log('浏览器不支持任何已定义的视频格式。');
      setLoadingShow(false);
      return;
    }
    let mediaRecorder: any;
    try {
      mediaRecorder = new MediaRecorder(stream, selectedFormat);
    } catch (e) {
      console.log('创建 MediaRecorder 失败。可能是由于不兼容的编码器或格式。', e);
      setLoadingShow(false);
      return;
    }
    mediaRecorder.ondataavailable = function (event: any) {
      krpano.set("autorotate.enabled", false);
      const blob = new Blob([event.data], { type: mediaRecorder.mimeType });
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onload = function () {
        const base64 = reader.result;
        if (/iPad|iPhone|iPod|Mac/.test(navigator.userAgent)) {
          setModalUrl(base64);
          setSaveFlag('ios');
          executePanoAction(ACTION_CHANGE_FISHEYE);
          // krapno.stopAll();
          setLoadingShow(false);
          setModalOpen(true);
        } else {
          const formData = new FormData();
          formData.append('file', blob);
          transcode(formData).then((res: any) => {
            setModalUrl(res[1]);
            setSaveFlag('android');
            executePanoAction(ACTION_CHANGE_FISHEYE);
            // krapno.stopAll();
            setLoadingShow(false);
            setModalOpen(true);
          });
        }
      };
    };

    // 开始录制
    try {
      mediaRecorder.start();
    } catch (e) {
      console.log('启动 MediaRecorder 失败。', e);
      setLoadingShow(false);
      return;
    }

    let timer: any = null;
    timer = setInterval(function () {
      if (checkRotationStatusFlag) {
        console.log('巡航结束，停止录制生成视频');
        clearInterval(timer);
        mediaRecorder.stop();
      }
    }, 1000);
  };

  // 生成视频之后 弹窗展示 点击确定
  function modalHandleOk() {
    setLoadingShow(true)
    executePanoAction(cruisingAction(0)); // 关闭巡航
    // 视频生成后 弹窗展示生成后的视频 并通过用户点击是否保存来判断是否进行保存视频
    miniProgram.postMessage({
      data: [{ type: saveFlag == 'ios' ? "videoUrlIOS" : "videoUrlAndroid", url: modalUrl }],
    });
    uma_data_report('视频保存相册', '')
    setLoadingShow(false)
    uni.reLaunch({ url: "/pages/index/index?page=page2" });
  }

  // 截图保存相册
  function modalHandleImgOk() {
    downLoadFunc(imageUrl);
  }
  // 取消保存相册
  function modalHandleImgCancel() {
    setImageFlag(false)
  }

  // 用户点击取消 不保存
  function modalHandleCancel() {
    executePanoAction(cruisingAction(0));
    setModalOpen(false)
  }
  // 陀螺仪开关
  function handleCheckedChange() {
    executePanoAction(ACTION_TOGGLE_GYRO);
  }
  // 三代设备
  function devicePano3() {
    return (
      <>
        {/* <Checkbox
          text="声音"
          style={styles.soundIcon}
          icon="img/icon/icon_sound_pause.png"
          highlightIcon="img/icon/icon_sound_play.png"
          onCheckedChange={handleSound}
        />
        <SizedBox style={styles.sizedBox} /> */}
        {/* {objectFlag == "works" && (
          <>
            <Radio
              text="水晶球"
              selected={radioState.crystalball}
              tag="crystalball"
              icon="img/radio/ic-crystalball-unchecked.png"
              highlightIcon="img/radio/ic-crystalball.png"
              onSelectedChange={handleSelectedChange}
            />
          </>
        )}
        <SizedBox style={styles.sizedBox} /> */}
        <Radio
          text="平面"
          tag="fisheye"
          selected={radioState.fisheye}
          icon="img/radio/ic-fisheye-unchecked.png"
          highlightIcon="img/radio/ic-fisheye.png"
          onSelectedChange={handleSelectedChange}
        />
        <SizedBox style={styles.sizedBox} />
        {objectFlag === "order" && (
          <Radio
            text="生成视频"
            tag="videoFn"
            selected={radioState.videoFn}
            icon="img/radio/ic-generateavideo-unchecked.jpg"
            highlightIcon="img/radio/ic-generateavideo.jpg"
            onSelectedChange={handleSelectedChange}
          />
        )}

        <SizedBox style={styles.sizedBox} />
        {/* <Radio
          text="全景"
          selected={radioState.smallplanet}
          tag="smallplanet"
          icon="img/radio/ic-smallplanet-unchecked.png"
          highlightIcon="img/radio/ic-smallplanet.png"
          style={styles.iconSmallplanet}
          onSelectedChange={handleSelectedChange}
        />
        <SizedBox style={styles.sizedBox} /> */}
        {(objectFlag === "works" || objectFlag === 'order') && (
          <Checkbox
            text="陀螺仪"
            icon="img/radio/ic-gyro-unchecked.png"
            highlightIcon="img/radio/ic-gyro.png"
            onCheckedChange={handleCheckedChange}
          />
        )}
        <SizedBox style={styles.sizedBox} />
        {/* {
          !orderData.panorama_flag && <>
            <Radio
              text="3D"
              selected={radioState.AI}
              tag="AI"
              icon="img/radio/uncheck3D.jpg"
              highlightIcon="img/radio/3D.jpg"
              style={styles.AI}
              onSelectedChange={handleSelectedChange}
            />
          </>
        } */}
        {/* {objectFlag == "order" && orderData.panorama_flag && (
          <Radio
            text="3D"
            selected={radioState.AI}
            tag="AI"
            icon="img/radio/uncheck3D.jpg"
            highlightIcon="img/radio/3D.jpg"
            onSelectedChange={render3D}
          />
        )} */}

        <SizedBox style={styles.sizedBox} />
        {objectFlag === "order" && (
          <>
            <Radio
              text="竖屏截图"
              selected={radioState.MakeScreenshot}
              tag="MakeScreenshot"
              icon="img/radio/portrait.png"
              highlightIcon="img/radio/SelectedPortrait.png"
              style={styles.iconSmallplanet}
              onSelectedChange={handleSelectedChange}
            />
          </>
        )}
        <SizedBox style={styles.sizedBox} />
        {objectFlag === "order" && (
          <>
            <Radio
              text="横屏截图"
              selected={radioState.MakeHiResScreenshot}
              tag="MakeHiResScreenshot"
              icon="img/radio/SelectedScenery.png"
              highlightIcon="img/radio/scenery.png"
              style={styles.iconSmallplanet}
              onSelectedChange={handleSelectedChange}
            />
          </>
        )}
      </>
    );
  }
  // 三代设备侧边栏
  function new_devicePano3() {
    return (
      <>
        {/* 原片订单声音 */}
        {
          (objectFlag == "order" || objectFlag == 'share' || given) && (
            <>
              <Checkbox
                text="声音"
                style={styles.soundIcon}
                // 选中
                icon="img/miniProgramIcon/icon_sound_play.png"
                // 未选中
                highlightIcon="img/miniProgramIcon/icon_sound_pause.png"
                onCheckedChange={handleSound}
              />
              <SizedBox style={styles.sizedBox} />
            </>
          )
        }
        {/* 作品水晶球 */}
        {/* {
          objectFlag == "works" && (
            <>
              <Radio
                text="水晶球"
                selected={radioState.crystalball}
                tag="crystalball"
                icon="img/miniProgramIcon/ic-crystalball-unchecked.png"
                highlightIcon="img/miniProgramIcon/ic-crystalball.png"
                onSelectedChange={handleSelectedChange}
              />
            </>
          )
        }
        <SizedBox style={styles.sizedBox} /> */}
        {/* <Radio
          text="平面"
          tag="fisheye"
          selected={radioState.fisheye}
          icon="img/radio/ic-fisheye-unchecked.png"
          highlightIcon="img/radio/ic-fisheye.png"
          onSelectedChange={handleSelectedChange}
        /> */}
        {/* <SizedBox style={styles.sizedBox} /> */}
        {/* 原片订单生成视频 */}
        {
          (objectFlag === "order" || objectFlag === 'share' || given) && (
            <Radio
              text="生成视频"
              tag="videoFn"
              selected={radioState.videoFn}
              icon="img/miniProgramIcon/ic-generateavideo.png"
              highlightIcon="img/miniProgramIcon/ic-generateavideo.png"
              onSelectedChange={handleSelectedChange}
            />
          )
        }
        <SizedBox style={styles.sizedBox} />
        {/* 原片订单视角切换 */}
        {
          (objectFlag == "order" || objectFlag == 'share' || given) && (
            <Radio
              text="视角切换"
              tag="watchFn"
              selected={radioState.watchFn}
              icon="img/miniProgramIcon/ic-generateavideo-unchecked.png"
              highlightIcon="img/miniProgramIcon/ic-generateavideo-unchecked.png"
              onSelectedChange={handleSelectedChange}
            />
          )
        }
        <SizedBox style={styles.sizedBox} />
        {/* 原片订单截图 */}
        {
          (objectFlag == "order" || objectFlag == 'share' || given) && (
            <Radio
              text="截屏"
              tag="screenshotFn"
              selected={radioState.screenshotFn}
              icon="img/miniProgramIcon/screen_shot.png"
              highlightIcon="img/miniProgramIcon/screen_shot.png"
              onSelectedChange={handleSelectedChange}
            />
          )
        }
        <SizedBox style={styles.sizedBox} />

        {/* <Radio
          text="全景"
          selected={radioState.smallplanet}
          tag="smallplanet"
          icon="img/radio/ic-smallplanet-unchecked.png"
          highlightIcon="img/radio/ic-smallplanet.png"
          style={styles.iconSmallplanet}
          onSelectedChange={handleSelectedChange}
        />
        <SizedBox style={styles.sizedBox} /> */}
        {/* 作品陀螺仪 */}
        {/* {
          objectFlag == "works" && (
            <>
              <Checkbox
                text="陀螺仪"
                icon="img/miniProgramIcon/ic-gyro-unchecked.png"
                highlightIcon="img/miniProgramIcon/ic-gyro.png"
                onCheckedChange={handleCheckedChange}
              />
            </>
          )
        } */}
        <SizedBox style={styles.sizedBox} />
        {/* 奖励机制 触发3D是否显示 */}
        {/* 原片订单3D */}
        {/* {
          //orderData.panorama_flag && 
          objectFlag == "order" && (
            <Radio
              text="3D"
              selected={radioState.AI}
              tag="AI"
              icon="img/radio/uncheck3D.jpg"
              highlightIcon="img/radio/3D.jpg"
              onSelectedChange={render3D}
            />
          )
        } */}
        {
          (objectFlag != "order" && objectFlag != 'share' && !given) && (
            <>
              <Checkbox
                text="声音"
                style={styles.soundIcon}
                // 选中
                icon="img/miniProgramIcon/icon_sound_play.png"
                // 未选中
                highlightIcon="img/miniProgramIcon/icon_sound_pause.png"
                onCheckedChange={handleSound}
              />
              <SizedBox style={styles.sizedBox} />
              <Radio
                text="水晶球"
                selected={radioState.crystalball}
                tag="crystalball"
                // 未选中
                icon="img/miniProgramIcon/ic-crystalball-unchecked.png"
                // 选中
                highlightIcon="img/miniProgramIcon/ic-crystalball.png"
                onSelectedChange={handleSelectedChange}
              />
              <SizedBox style={styles.sizedBox} />
              <Radio
                text="全景"
                tag="fisheye"
                selected={radioState.fisheye}
                icon="img/miniProgramIcon/ic-fisheye-unchecked.png"
                highlightIcon="img/miniProgramIcon/ic-fisheye.png"
                onSelectedChange={handleSelectedChange}
              />
              <SizedBox style={styles.sizedBox} />
              <Radio
                text="小行星"
                selected={radioState.smallplanet}
                tag="smallplanet"
                icon="img/miniProgramIcon/ic-smallplanet-unchecked.png"
                highlightIcon="img/miniProgramIcon/ic-smallplanet.png"
                style={styles.iconSmallplanet}
                onSelectedChange={handleSelectedChange}
              />
              <SizedBox style={styles.sizedBox} />
              <Checkbox
                text="陀螺仪"
                icon="img/miniProgramIcon/ic-gyro-unchecked.png"
                highlightIcon="img/miniProgramIcon/ic-gyro.png"
                onCheckedChange={handleCheckedChange}
              />
            </>
          )
        }


        {/* <SizedBox style={styles.sizedBox} />
        {objectFlag == "order" && (
          <>
            <Radio
              text="竖屏截图"
              selected={radioState.MakeScreenshot}
              tag="MakeScreenshot"
              icon="img/radio/portrait.png"
              highlightIcon="img/radio/SelectedPortrait.png"
              style={styles.iconSmallplanet}
              onSelectedChange={handleSelectedChange}
            />
          </>
        )}
        <SizedBox style={styles.sizedBox} />
        {objectFlag == "order" && (
          <>
            <Radio
              text="横屏截图"
              selected={radioState.MakeHiResScreenshot}
              tag="MakeHiResScreenshot"
              icon="img/radio/SelectedScenery.png"
              highlightIcon="img/radio/scenery.png"
              style={styles.iconSmallplanet}
              onSelectedChange={handleSelectedChange}
            />
          </>
        )} */}
      </>
    );
  }
  // 其他设备
  function devicePano() {
    return (
      <>
        <Radio
          text="水晶球"
          selected={radioState.crystalball}
          tag="crystalball"
          // 未选中
          icon="img/miniProgramIcon/ic-crystalball-unchecked.png"
          // 选中
          highlightIcon="img/miniProgramIcon/ic-crystalball.png"
          onSelectedChange={handleSelectedChange}
        />
        <SizedBox style={styles.sizedBox} />
        <Radio
          text="全景"
          tag="fisheye"
          selected={radioState.fisheye}
          icon="img/miniProgramIcon/ic-fisheye-unchecked.png"
          highlightIcon="img/miniProgramIcon/ic-fisheye.png"
          onSelectedChange={handleSelectedChange}
        />
        <SizedBox style={styles.sizedBox} />
        <Radio
          text="小行星"
          selected={radioState.smallplanet}
          tag="smallplanet"
          icon="img/miniProgramIcon/ic-smallplanet-unchecked.png"
          highlightIcon="img/miniProgramIcon/ic-smallplanet.png"
          style={styles.iconSmallplanet}
          onSelectedChange={handleSelectedChange}
        />
        <SizedBox style={styles.sizedBox} />
        <Checkbox
          text="陀螺仪"
          icon="img/miniProgramIcon/ic-gyro-unchecked.png"
          highlightIcon="img/miniProgramIcon/ic-gyro.png"
          onCheckedChange={handleCheckedChange}
        />
        {objectFlag === "order" && (
          <>
            <Radio
              text="竖屏截图"
              selected={radioState.MakeScreenshot}
              tag="MakeScreenshot"
              icon="img/radio/portrait.png"
              highlightIcon="img/radio/SelectedPortrait.png"
              style={styles.iconSmallplanet}
              onSelectedChange={handleSelectedChange}
            />
          </>
        )}
        <SizedBox style={styles.sizedBox} />
        {objectFlag === "order" && (
          <>
            <Radio
              text="横屏截图"
              selected={radioState.MakeHiResScreenshot}
              tag="MakeHiResScreenshot"
              icon="img/radio/SelectedScenery.png"
              highlightIcon="img/radio/scenery.png"
              style={styles.iconSmallplanet}
              onSelectedChange={handleSelectedChange}
            />
          </>
        )}
        <SizedBox style={styles.sizedBox} />
      </>
    );
  }
  // 引导页步骤展示判断
  function nextStep() {
    if (maskNum < 4) {

      setMaskNum(maskNum + 1)
    } else {
      setGuidePage(false)
    }
  }
  // 引导页
  function Popup() {
    return <>
      <div style={styles.maskLayer}>
        {
          maskNum == 1 ? <>
            <img style={styles.maskBox} src="img/radio/dialogBox.png" alt="" />
            <img style={styles.spiralGuideLine} src="img/radio/spiralGuideLine.png" alt="" />
            <div style={styles.text}>
              点这里开启现场背景音欣赏有声全景大片~
            </div>
          </> : maskNum == 2 ? <>
            <img style={styles.maskBox1} src="img/radio/dialogBox.png" alt="" />
            <img style={styles.spiralGuideLine1} src="img/radio/spiralGuideLine.png" alt="" />
            <div style={styles.text1}>
              点这里可以将全景照片生成动态视频并保存至手机相册哦~
            </div>
          </> : maskNum == 3 ? <>
            <img style={styles.maskBox2} src="img/radio/dialogBox.png" alt="" />
            <img style={styles.spiralGuideLine2} src="img/radio/spiralGuideLine.png" alt="" />
            <div style={styles.text2}>
              点这里可以切换任意视角，多角度欣赏炫酷全景大片~
            </div>
          </> : <>
            <img style={styles.maskBox3} src="img/radio/dialogBox.png" alt="" />
            <img style={styles.spiralGuideLine3} src="img/radio/spiralGuideLine.png" alt="" />
            <div style={styles.text3}>
              点这里可以截取任意角度和效果的画面，并保存至手机相册~
            </div>
          </>
        }
        <img style={styles.gotIt} onClick={nextStep} src="img/radio/gotIt.png" alt="" />
      </div>
    </>
  }
  return (
    <div>
      {/* 截图展示 */}
      {
        imageFlag && imageUrl && <DialogImage
          src={imageUrl}
          show={imageFlag}
          onConfirm={modalHandleImgOk}
          onCancel={modalHandleImgCancel}
          positiveTitle="保存到相册"
          negativeTitle="取消保存"
        />
      }
      {
        isModalOpen && <Dialog
          title={title}
          show={isModalOpen}
          onConfirm={handleOk}
          onCancel={handleCancel}
          okText="确定"
          cancelText="取消"
        />
      }
      {/* okText="视频保存到相册"
        cancelText="取消" */}
      {
        modalOpen && modalUrl && <DialogCopy
          src={modalUrl}
          show={modalOpen}
          onConfirm={modalHandleOk}
          onCancel={modalHandleCancel}
          positiveTitle="视频保存到相册"
          negativeTitle="取消保存"
        />
      }

      {
        protFlag && <Dialog
          title={portTitle}
          show={protFlag}
          onConfirm={protHandleOk}
          onCancel={protHandleCancel}
          okText="确定"
          cancelText="取消"
        />
      }

      {/* 视频 */}
      {
        cruiseShow && <Cruise
          trajectoryMovementFn={trajectoryMovement}
          show={cruiseShow}
          cruiseData={dataTree["巡航"][0]}
        />
      }
      {/* 观看 */}
      {
        watchShow && <WatchCruise
          show={watchShow}
          watchData={watchData}
          onSelectedChange={handleSelectedChange}
          onCheckedChange={handleCheckedChange}
        />
      }
      {/* 截图 */}
      {
        screenshotShow && <ScreenshotCruise
          show={screenshotShow}
          screenshotData={screenshotData}
          onSelectedChange={handleSelectedChange}
        />
      }

      <Loading show={loadingShow} />

      {/* {
        objectFlag == "order" && guidePage && Popup()
      } */}
      {
        new_devicePano3()
        // devicePano3()
      }
      {
        // deviceAppCode > -1 ? devicePano3() : devicePano()
      }
    </div>
  );
}

const styles: any = {
  maskLayer: {
    width: '100%',
    height: '100%',
    position: 'fixed',
    left: '0',
    top: '0',
    background: 'rgba(0, 0, 0, 0.5)',
    zIndex: 1010,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    // position: 'relative'
  },
  gotIt: {
    position: 'absolute',
    bottom: 100
  },
  maskBox: {
    position: 'absolute',
    bottom: 420,
  },
  spiralGuideLine: {
    position: 'absolute',
    bottom: 350,
    right: '10%',
  },
  text: {
    position: 'absolute',
    bottom: 445,
    right: '15%',
    color: 'rgb(65, 84, 98)',
    width: '50vw',
    height: '10vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  maskBox1: {
    position: 'fixed',
    bottom: 340,
    width: '80vw',
  },
  spiralGuideLine1: {
    position: 'fixed',
    bottom: 270,
    right: '10%',
  },
  text1: {
    position: 'fixed',
    bottom: 365,
    right: '15%',
    color: 'rgb(65, 84, 98)',
    width: '50vw',
    height: '10vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  maskBox2: {
    position: 'fixed',
    bottom: 280,
    width: '80vw',
  },
  spiralGuideLine2: {
    position: 'fixed',
    bottom: 210,
    right: '10%',
  },
  text2: {
    position: 'fixed',
    bottom: 305,
    right: '15%',
    color: 'rgb(65, 84, 98)',
    width: '50vw',
    height: '10vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  maskBox3: {
    position: 'fixed',
    bottom: 220,
    width: '80vw',
  },
  spiralGuideLine3: {
    position: 'fixed',
    bottom: 150,
    right: '10%',
  },
  text3: {
    position: 'fixed',
    bottom: 245,
    right: '15%',
    color: 'rgb(65, 84, 98)',
    width: '50vw',
    height: '10vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  icon_sound_pause: {
    position: 'absolute',
    top: '45%',
    right: '0'
  },
  modalContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    background: 'rgba(255, 255, 255, 0.5)',
    backdropFilter: 'blur(2px)',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    zIndex: 1009,
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: Color.moduleBgColor,
    paddingTop: 30,
    width: '80%',
    borderRadius: 10,
  },
  content: {
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 30,
    paddingRight: 30,
    color: "#D5D5D5FF",
    fontWeight: '600',
    textAlign: 'center',
  },
  line: {
    height: 1,
    marginTop: 20,
    backgroundColor: Color.line,
  },
  buttonContainer: {
    display: 'flex',
  },
  button: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    color: '#D5D5D5FF',
    fontWeight: '600',
    paddingTop: 12,
    paddingBottom: 12,
  },
  positiveButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    color: Color.orange,
    fontWeight: '600',
    paddingTop: 12,
    paddingBottom: 12,
  },
  container: {
    textAlign: "center",
    justAlgin: "center",
  },
  sizedBox: {
    height: 5,
  },
  soundIcon: {
    width: 38,
    height: 38,
    filter: "drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.3))",
  },
  modelBox: {
    width: 38,
    height: 38,
  },
  iconSmallplanet: {
    width: 38,
    height: 38,
    zIndex: 100,
    textAlign: "center",
    justAlgin: "center",
  },
};

export default RadioGroupPicture;
