/**
 * Created by MeePwn
 * https://github.com/maybewaityou
 *
 * description:
 *
 */
import { executePanoAction } from './PanoramicView';
import { saveTextStickerPoint, getPanoData, dynamicNameByIdMap } from './data';
import { Wormhole, Destination } from '../../vendor/wormhole/index';
import { processURL } from '../../utils/data';
import { delay } from '../../utils/functional';

export const ACTION_HIDE_ALL = 'hideall();';
export const ACTION_SHOW_ALL = 'showall();';

// 照片
export const ACTION_TOGGLE_GYRO = 'toggle_gyro();'; // 切换陀螺仪
export const ACTION_CHANGE_CRYSTAL_BALL = 'change_view(3);'; // 水晶球
export const ACTION_CHANGE_SMALL_PLANET = 'change_view(2);'; // 小行星
export const ACTION_CHANGE_FISHEYE = 'change_view(1);'; // 鱼眼

export const ACTION_CHANGE_FILTER = 'resetFilter()'; // 滤镜
export const STICKER_BOX_HIDE = 'sticker_box_hide();'; // 贴纸隐藏边框
export const STICKER_BOX_SHOW = 'sticker_box_show();'; // 贴纸显示边框
export const ACTION_DOWN_LOAD = 'MakeScreenshot(true,2250,4000)'; //下载
export const ACTION_MAKE_SCREENSHOT = 'makescreenshot(true,4000,2250)'; //制作高分辨率截图
export const ACTION_SOUND_PLAY = 'soundplay();'; // 全景声开启
export const ACTION_SOUND_PAUSE = 'soundpause();'; // 全景声关闭

// 视频
export const ACTION_TOGGLE_GYRO_PERMISSION = 'ios_gyro_permission();'; // 切换陀螺仪
export const ACTION_SKIN_VIEW_NORMAL = 'skin_view_normal();'; // 正常
export const ACTION_SKIN_VIEW_FISHEYE = 'skin_view_fisheye();'; // 鱼眼
export const ACTION_SKIN_VIEW_LITTLEPLANET = 'skin_view_littleplanet();'; // 星球
export const ACTION_SKIN_VIEW_STEREOGRAPHIC = 'skin_view_stereographic();'; // 立体



/* 动效 */

/**
 * 无动效
 */
export function noneDynamicAction() {
  return `nonesnow();`;
}
/**
 * 动效
 */
export function dynamicAction(item: any) {
  // 改造后-后管平台新上传数据
  if (item.imageurl) {
    return `dynamic(${item.id}, ${processURL(item.imageurl)}, ${item.invert});`;
  }
  // 改造前存量数据
  return `${dynamicNameByIdMap(item.id)}(${item.id});`;
}

/* 配乐 */
export function startBGM(url: string) {
  const bgmItem = getPanoData().bgmItem || getPanoData().jsonData.bgmItem;
  Wormhole.getInstance().enter(Destination.UPDATE_BGM_ITEM, { ...bgmItem, shouldPlay: true });
  return '';
  // return `
  // trace('播放方法000');
  // playsound(bgsnd, ${url}, true);
  // set(sound[bgsnd].onplay,trace('播放'));
  // set(sound[bgsnd].onstop,trace('停止'));
  // trace('播放方法111');
  // `;
}

export function stopBGM() {
  const bgmItem = getPanoData().bgmItem || getPanoData().jsonData.bgmItem;
  Wormhole.getInstance().enter(Destination.UPDATE_BGM_ITEM, { ...bgmItem, shouldPlay: false, });
  return '';
  // return `
  // trace('播放停止000');
  // trace(sound[bgsnd]);
  // sound[bgsnd].stop();
  // trace('播放停止111');
  // `;
}

export function saveBGM(url: string) {
  return `save_music_json(${url});`;
}

/* 裁剪 */
// 选择边框
export const ACTION_CHOOSE_ASPECT_1 = 'choose_aspect(1);';
export const ACTION_SHOW_BOX = 'show_box()';
export const ACTION_HIDE_BOX = 'hide_box()';
export function chooseAspectAction(value: any) {
  return `choose_aspect(${value});`;
}
// 截屏(全屏)
export const ACTION_SCREENSHOT = 'makescreenshot(true);';
export function getScreenshotURL() {
  return 'layer[screenshotimage].url';
}

/* 边框 */
/**
 * 显示边框
 */
export function topImageAction(value: any) {
  return `show_topimg(${value}, 10);`;
}
/**
 * 隐藏边框
 */
export function hideTopImageAction() {
  // 移除边框存储
  getPanoData().topimg = { url: '', framerate: '10', };
  return 'hide_topimg();';
}
/**
 * 保存边框
 */
export function saveTopImageAction(value: any, id = '') {
  return `save_topimg_json(${id}, ${value}, 10);`;
}

// 文字贴纸
export let selectedTextSticker = {
  id: '',
  text: '',
  atv: '',
  ath: '',
};

export function resetSelectedTextSticker() {
  selectedTextSticker = {
    id: '',
    text: '',
    atv: '',
    ath: '',
  };
}

export function updateSelectedTextSticker(textSticker: any) {
  selectedTextSticker = { ...textSticker };
}

export function textStickerAction(value: any, item: any) {
  const point = getCenterPointInPano();

  selectedTextSticker = {
    id: `text-sticker-${Math.floor(Math.random() * 100 + 1)}`,
    text: value,
    atv: `${point.atv}`,
    ath: `${point.ath}`,
  };

  executePanoAction(`add_text_sticker(${selectedTextSticker.id}, ${selectedTextSticker.text
    }, ${selectedTextSticker.atv}, ${selectedTextSticker.ath}, ${true})`);

  // 保存文字贴纸
  saveTextStickerPoint(selectedTextSticker.id, selectedTextSticker.text, selectedTextSticker.atv, selectedTextSticker.ath);

  return `add_text_sticker_confirm(${selectedTextSticker.id});`;
  // return `add_textsticker(true,null,${value},null,${point.atv},${point.ath});`
}

// 巡航
export function cruisingAction(value: any) {
  return `change_rdate(${value});`
}

// 贴纸
export function stickerAction(id: string, value: any) {
  return `add_sticker(true, ${id}, ${processURL(value)}, 10);`;
}

/**
 * 更新中心点
 */
export function getCenterPointInPano() {
  const { krpano } = window as any;
  const atv = krpano.get('view.vlookat');
  const ath = krpano.get('view.hlookat');
  return {
    atv,
    ath,
  };
}

export function getPanoProperty(key: string) {
  const { krpano } = window as any;
  return krpano.get(key);
}

/**
 * pano 加载 XML 完成事件
 */
export function loadPanoXMLComplete() {
  const music = getPanoData().music || getPanoData().jsonData.music;
  if (music) {
    // alert(getPanoData().music);
    // 先停止播放音乐
    // alert(`${stopBGM()}stopBGM()`)
    executePanoAction(stopBGM());
    // 开始音乐
    // alert(`${startBGM(music)}startBGM(music)`)
    executePanoAction(startBGM(music));

  }
}

/**
 * 视频模板
 */
export function templateAction() {

}

/**
 * 视频视角
 */
export function visualAction(value: any) {
  return `set_motion(${value})`
}

/**
 * 视频配乐
 */
export function BGMAction() {

}

/**
 * 视频动效
 */
export function dynamicVideoAction(item: any) {
  if (item.type === "topimg") {
    return `show_topimg(${item.value.id}, ${processURL(item.value.imageurl)}, ${item.value.framerate});`;
  }
  if (item.type === "intro") {
    const h = item.value === 'h' || getPanoData().jsonData.intro.h;
    const v = item.value === 'v' || getPanoData().jsonData.intro.v;
    const he = item.value === 'he' || getPanoData().jsonData.intro.he;
    const ve = item.value === 've' || getPanoData().jsonData.intro.ve;
    return `set_intro(${v}, ${h}, ${ve}, ${he});`;
  }
  if (item.type === "crop") {
    return `set_crop_intro(${item.value.imageurl}, ${item.value.framerate}, ${item.value.startOrEnd});`
  }
  return item.value;
}

/**
 * 视频动效取消
 */
export function dynamicVideoCancel(item: any) {
  if (item.type === "topimg") {
    return `hide_topimg();`;
  }
  if (item.type === "intro") {
    const h = item.value === 'h' ? false : getPanoData().jsonData.intro.h;
    const v = item.value === 'v' ? false : getPanoData().jsonData.intro.v;
    const he = item.value === 'he' ? false : getPanoData().jsonData.intro.he;
    const ve = item.value === 've' ? false : getPanoData().jsonData.intro.ve;
    return `set_intro(${v}, ${h}, ${ve}, ${he});`;
  }
  if (item.type === "crop") {
    return `set_crop_intro(, ,${item.cancel});`
  }
  return item.cancel;
}

/**
 * 视频滤镜
 */
export function filterAction(value: any) {
  return `set_zfilter(${value})`;
}

/**
 * 隐藏视角
 */
export function hideVisualButton() {
  return 'set(layer[skin_video_view].visible,false)';
}
(window as any).loadPanoXMLComplete = loadPanoXMLComplete;

export function loadComplete(type: string) {
  // console.log(`== loadComplete ===>>>> ${type}`);
  // console.log(`== WebViewJavascriptBridge ===>>>> ${(window as any).WebViewJavascriptBridge}`);

  (window as any).WebViewJavascriptBridge &&
    (window as any).WebViewJavascriptBridge.callHandler(
      'loadComplete',
      { autoRotate: type },
      (responseData: String) => {
        console.log(`== responseData ===>>>> ${responseData}`);
      },
    );
}
(window as any).loadComplete = loadComplete;

// 全景声
export async function onSound3D() {
  await delay(0);
  executePanoAction('sound1click();sound2click();sound3click();sound4click();');
}
