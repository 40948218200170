/**
 * Created by MeePwn
 * https://github.com/maybewaityou
 *
 * description:
 *
 */
import React from 'react';

export function PhotoView(props: any) {
  // console.log('PhotoViewPhotoViewPhotoViewPhotoView', props.image);
  // console.log('================== 2 ==================');

  return (
    <div style={styles.container}>
      <img src={props.image} alt="" style={styles.image} />
    </div>
  );
}

const styles: any = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    zIndex: 1,
  },
  image: {
    width: '100%',
    height: '100%',
  },
}

export default PhotoView;
