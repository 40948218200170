/**
 * Created by MeePwn
 * https://github.com/maybewaityou
 *
 * description:
 *
 */
import { getPanoData } from './data';
import { getQueryString } from '../../utils/functional';
import { processURL } from '../../utils/data';
import { sound } from './functional';

export enum TemplateType {
  cube = 'cube',
  sphere = 'sphere',
}
// <cube url="${item.url}/%s/l%l/%v/l%l_%s_%v_%h.jpg" multires="500,2000" />
// <cube url="${item.url}/%s/l%l/%v/l%l_%s_%v_%h.jpg" multires="720,2880,1440,720" />
//  <cube url="${item.url}/%s/l%l/%v/l%l_%s_%v_%h.jpg" multires="960,3840" /> // 三代设备

/*
  <cube url="${item.url}/%s/l%l/%v/l%l_%s_%v_%h.jpg" />
  %s 占位符展示图片 f是前 b是后 l是左 r是右 u是上 d是下
*/

/**
 * 二代设备：
 * preview striporder="LFRBUD" preview.jpg拼接顺序为“左前右后上下”
 * 正前方角度hlookat为 135.0
 * 三代代设备:
 * preview striporder="FLBRUD" preview.jpg拼接顺序为“前左后右上下”
 * 正前方角度hlookat为 45.0
 * @returns 
 */


function removeLastSegment(url: string) {
  const urlParts = url.split('/');
  urlParts.pop(); // 移除最后一个部分
  return urlParts.join('/'); // 重新连接为字符串
}

export function targetTemplate() {
  const skyPreview = processURL(getPanoData().skyPreview.sphereurl); // 换天预览模式
  console.log('getPanoData :', getPanoData());

  const scenes = getPanoData().scene.map((item: any) => {
    const items = {
      ...item,
      url: removeLastSegment(item.url)
    }
    const code = getQueryString("deviceAppCode") || items.deviceAppCode || "";
    const deviceAppCode = code.indexOf('app_code_3');
    const hlookat = deviceAppCode > -1 ? 45.0 : 135.0; // 二代设备/三代设备视角
    const jpg = items.url.indexOf('jpg');
    const png = items.url.indexOf('png');
    items.url = items.url.replace('file.test', 'file-test');
    items.sphereurl = processURL(items.sphereurl).replace('file.test', 'file-test');
    // console.log('item :', items);
    const imageTemplate = skyPreview || item.sphereurl || jpg > -1 || png > -1 ?
      `
     <image>
       <sphere url="${skyPreview || item.sphereurl || item.url}"/>
     </image>
     ` :
      `
     <preview url="${item.url}/preview.jpg" striporder="${deviceAppCode > -1 ? "FLBRUD" : "LFRBUD"}" />
     <image preload="true" keep="true" stereo="true" stereolabels="l|r" stereooffset="2.05" prealign="100" multires="true" progressive="false" preloadrange="150">
       <cube url="${item.url}/%s/l%l/%v/l%l_%s_%v_%h.jpg" multires="${deviceAppCode > -1 ? '960,3840' : '500,2000'}" />
     </image>
     `;
    return `
     <scene name="scene_${item.id}" title="${item.title}" onstart="" thumburl="${item.url}tiny_planet.jpg" lat="" lng="" heading="">
       <view hlookat="${hlookat}"  vlookat="0.0" fovtype="MFOV" fov="90" fovmin="20" fovmax="180" limitview="auto" />
       ${imageTemplate}
       ${sound(hlookat, 'picture')}
     </scene>
     `;
    // console.log('item.url :', items.url);

    // const imageTemplate = skyPreview || items.sphereurl || jpg > -1 || png > -1 ?
    //   `
    //  <image>
    //    <sphere url="${skyPreview || items.sphereurl || items.url}"/>
    //  </image>
    //  ` :
    //   `
    //   <image>
    //     <sphere url="${items.url}/panorama_11k.jpg"/>
    //   </image>
    //  `
    // return `
    //  <scene name="scene_${items.id}" title="${items.title}" onstart="" thumburl="${items.url}tiny_planet.jpg" lat="" lng="" heading="">
    //    <view hlookat="${hlookat}"  vlookat="0.0" fovtype="MFOV" fov="90" fovmin="20" fovmax="180" limitview="auto" />
    //    ${imageTemplate}
    //    ${sound(hlookat, 'picture')}
    //  </scene>
    //  `;
  }).join('');
  console.log(`
     <krpano>
       <include url="pano.xml" />
       ${scenes}
     </krpano>
   `);
  return `
     <krpano>
       <include url="pano.xml" />
       ${scenes}
     </krpano>
   `;
}

// <image preload="true" keep="true" stereo="true" stereolabels="l|r" stereooffset="2.05" prealign="100" multires="true">
// <cube url="${item.url}/%s/l%l/%v/l%l_%s_%v_%h.jpg" multires="${deviceAppCode > -1 ? '960,3840' : '500,2000'}" />
// </image>

// <preview url="${item.url}/preview.jpg" striporder="${deviceAppCode > -1 ? "FLBRUD" : "LFRBUD"}" />
// <image stereo="true" stereolabels="l|r" stereooffset="5.25" prealign="0|25|0">
//   <cube url="${item.url}/%s/l%l/%v/l%l_%s_%v_%h.jpg" multires="${deviceAppCode > -1 ? '960,3840' : '500,2000'}" />
// </image>

