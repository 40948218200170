/**
 * Created by MeePwn
 * https://github.com/maybewaityou
 *
 * description:
 *
 */
import { useEffect, useRef } from 'react';
import { PanoramicViewConfig } from './index.d';
import { getPanoData } from './data';
import { targetTemplate } from './template';
import { templateVideo } from './templateVideo';
import { fromShare, isTemplate, shouldEdit, panoPicture, getQueryString, delay } from '../../utils/functional';
import { stopBGM, hideVisualButton } from './actions';
import { Wormhole, Destination } from '../../vendor/wormhole/index';
import { Constant } from '../../constant/Constant';
import { SelectedTab } from '../tab/data/dataPicture';
import { onSound3D } from './actions';

export function executePanoAction(action: string) {
  const { krpano } = window as any;
  // console.log(action, 'action');
  krpano.call(action);
}

export function getDataFromPano(action: string) {
  const { krpano } = window as any;
  console.log(action);
  return krpano.get(action);
}

// 全景照片xml字符串生成
export function dynamicLoadPanoXML() {
  const { krpano } = window as any;
  // krpano.call(`loadxml(${targetTemplate()}, null, MERGE, BLEND(1), loadPanoXMLComplete());`);
  krpano.call(`loadxml(${targetTemplate()}, null, MERGE, BLEND(1));`);
  // delay(500).then(onSound3D);
}

// 全景视频xml字符串生成
export function dynamicLoadPanoVideoXML() {
  const { krpano } = window as any;
  // debugger
  krpano.call(`loadxml(${escape(templateVideo())});`);
  // if(shouldEdit()) {
  //   executePanoAction(hideVisualButton());
  // }
  // 隐藏视角
  executePanoAction(hideVisualButton());
  // delay(0).then(onSound3D);
}


export function reloadPanoXML() {
  const { embedpano, removepano, } = window as any;
  // // 停止播放音乐
  executePanoAction(stopBGM());
  removepano('krpanoSWFObject');
  embedpano({
    xml: null,
    target: 'pano',
    html5: 'only+webgl',
    initvars: {
      editmode: true,
      debugmode: Constant.DEBUG,
    },
    mobilescale: 1.0,
    passQueryParameters: true,
    onready: function (krpano: any) {
      (window as any).krpano = krpano;
      // 根据 URL 判断贴纸是否可编辑
      (window as any).settings = { editmode: !fromShare(), };
      // if (!fromShare() || !isTemplate()) unlockStickersAndTextStickers(SelectedTab.贴纸);
      if (shouldEdit()) { // 编辑状态
        // 解锁标签
        lockStickersAndTextStickers(SelectedTab.标签);
      }
      dynamicLoadPanoXML();
    },
  });
}

/**
 * 锁住贴纸和文字贴纸
 */
export function lockStickersAndTextStickers(tag: SelectedTab) {
  const { krpano } = window as any;
  if (tag === SelectedTab.贴纸) {
    krpano.set('global.editobject', 'textsticker');
  };
  if (tag === SelectedTab.标签) {
    krpano.set('global.lock', true);
  };
}

/**
 * 解锁贴纸和文字贴纸
 */
export function unlockStickersAndTextStickers(tag: SelectedTab) {
  const { krpano } = window as any;
  if (tag === SelectedTab.贴纸) {
    krpano.set('global.editobject', 'sticker');
  };
  if (tag === SelectedTab.标签) {
    krpano.set('global.lock', false);
  };
}

export function PanoramicView(props: PanoramicViewConfig) {
  const { xml, target, initVars, style, onReady, refresh } = props;
  const panoRef = useRef(null);
  useEffect(() => {
    const { embedpano } = window as any;
    embedpano({
      xml: null,
      target,
      html5: 'only+webgl',
      initvars: {
        editmode: true,
        debugmode: Constant.DEBUG,
      },
      mobilescale: 1.0,
      passQueryParameters: true,
      onready: function (krpano: any) {
        (window as any).krpano = krpano;
        // 根据 URL 判断贴纸是否可编辑
        (window as any).settings = { editmode: !fromShare() };
        if (refresh !== '0') return;

        // if(typeof (window as any).WeixinJSBridge !== "undefined"){
        //   if (document.addEventListener) {
        //     document.addEventListener('WeixinJSBridgeReady', () => {
        //       krpano.call("trace('xxplay');set(global.bgmusic,true);callwhen(plugin[bgmusic_interface].loaded,playsound(bgsnd,get(global.bgmusicurl),0););");
        //     } , false);
        //   }
        // }

        getPanoData().scene[0].url = initVars.url;
        onReady && onReady(krpano);
      },
    });

    const panoWormholePipe = Wormhole.getInstance().destination(Destination.UPDATE_PANO_STYLE, (fullscreen: any) => {
      if (fullscreen) {
        (panoRef.current as any).style = `position: absolute; top: 0; left: 0; bottom: ${fullscreen}px; right: 0;`;
      } else {
        (panoRef.current as any).style = 'position: absolute; top: 0; left: 0; bottom: 0; right: 0;';
      }
    });
    return () => {
      Wormhole.getInstance().close(panoWormholePipe);
    };
  }, [xml, target, initVars, style, onReady, refresh]);
  return (
    <div id="pano" ref={panoRef} style={{ ...styles.container, ...style }}>
      <noscript>
        <table style={styles.container}>
          <tr style={styles.center}>
            <td>
              <div style={styles.center}>
                ERROR:
                <br />
                <br />Javascript not activated
                <br />
                <br />
              </div>
            </td>
          </tr>
        </table>
      </noscript>
    </div>
  );
}

// 设置编辑模式，true/false
function setEditmode(b = false) {
  const { krpano } = window as any;
  getPanoData().settings.editmode = b;
  krpano.set("global.editmode", b);
}

// 全景视频
export function PanoramicVideoView(props: any) {
  const { xml, target, style, initVars, vars, onReady } = props;
  const panoRef = useRef(null);
  useEffect(() => {
    // const { embedpano } = window as any;
    // embedpano({
    //   xml: null,
    //   target,
    //   html5: "only+webgl",
    //   mobilescale: 1.0,
    //   passQueryParameters: true,
    //   vars,
    //   initvars: getPanoData().settings,
    //   onready: function (krpano: any) {
    //     (window as any).krpano = krpano;
    //     // 进入编辑模式
    //     if (!fromShare()) {
    //       setEditmode(true);
    //     };
    //     // dynamicLoadPanoVideoXML();
    //     getPanoData().jsonData.scene[0].video = initVars.url;
    //     onReady && onReady(krpano);
    //   },
    // });
    const panoWormholePipe = Wormhole.getInstance().destination(Destination.UPDATE_PANO_STYLE, (fullscreen: any) => {
      // const height = window.screen.height;
      if (fullscreen) {
        (panoRef.current as any).style = `position: absolute; top: 0; left: 0; bottom: ${fullscreen}px; right: 0;z-index:1;`;
      } else {
        (panoRef.current as any).style = 'position: absolute; top: 0; left: 0; bottom: 0; right: 0; ';
      }
    });
    return () => {
      Wormhole.getInstance().close(panoWormholePipe);
    };
  }, [xml, target, initVars, style, vars, onReady]);
  return (
    <div id="panoVideo" ref={panoRef} style={{ ...styles.container, ...style }}>
      <noscript>
        <table style={styles.container}>
          <tr style={styles.center}>
            <td>
              <div style={styles.center}>
                ERROR:
                <br />
                <br />Javascript not activated
                <br />
                <br />
              </div>
            </td>
          </tr>
        </table>
      </noscript>
    </div>
    // <div id="panoVideo" style={{ width:'100%' ,height:'68%' }}>
    //   <noscript><table style={{ width:'100%' ,height:'100%' }}><tr style={{verticalAlign: 'middle'}}><td><div style={{textAlign: 'center'}}>ERROR:<br/><br/>Javascript not activated<br/><br/></div></td></tr></table></noscript>
    // </div>
  );
}

export function reloadPanoVideoXML() {
  const { embedpano, removepano, krpano } = window as any;
  // const { xml, target, style, initVars, vars, onReady } = props;
  let url = getQueryString('initvars.mypath');
  let posterUrl = getQueryString('poster');
  console.log(getPanoData())

  // 清楚krpano缓存
  // removepano('krpanoSWFObject');
  if (krpano) {
    removepano("panoVideoViwe");
  }

  embedpano({
    xml: null,
    id: "panoVideoViwe",
    target: 'panoVideo',
    html5: "only+webgl",
    mobilescale: 1.0,
    passQueryParameters: true,
    vars: {},
    initvars: getPanoData().settings,
    onready: function (krpano: any) {
      (window as any).krpano = krpano;
      // 进入编辑模式
      if (!fromShare()) {
        setEditmode(true);
      };
      // 封面
      if (posterUrl) {
        getPanoData().jsonData.scene[0].poster = posterUrl;
      }
      getPanoData().jsonData.scene[0].video = url;
      dynamicLoadPanoVideoXML();
      // onReady && onReady(krpano);
    },
  });
}

const styles: any = {
  container: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    // zIndex: 1,
  },
  center: {
    justifyContent: 'center',
    alignItems: 'center',
  },
}

export default PanoramicView;
