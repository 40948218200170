/**
 * Created by MeePwn
 * https://github.com/maybewaityou
 *
 * description:
 *
 */
import { useState, useEffect } from 'react';
import PanoramicView, { PanoramicVideoView } from './components/panoramic/PanoramicView';
import PhotoView from './components/photo/PhotoView';
import ChannelDispatcher from './dispatcher/ChannelDispatcher';
import { Channel } from './dispatcher/data';
import { getTitle, getQueryString, normalPicture, panoPicture, officialPicture, devicePicture } from './utils/functional';
import { fetchDataConfigById } from './api/NetworkApi'

function App() {
  const [refresh, setRefresh] = useState('0');
  const { aplus_queue }: any = window;
  useEffect(() => {
    document.title = getTitle();
    aplus_queue.push({
      action: "aplus.record",
      arguments: ['page_initialize', 'CLK', {
        'param': '初始化加载页面'
      }],
    });
  }, []);

  let url = getQueryString('initvars.mypath');
  const type = devicePicture() ? Channel.device : getQueryString('type') ? Channel.miniProgram : Channel.official;

  return (
    // 首页 清屏模式
    <div style={styles.container}>
      <ChannelDispatcher
        type={type}
        setRefresh={setRefresh}
      />
      {
        normalPicture() ?
          <PhotoView
            image={url}
          /> : panoPicture() || officialPicture() || devicePicture() ?
            <PanoramicView
              refresh={refresh}
              xml="pano.xml"
              target="pano"
              initVars={{
                type: 'cube',
                url,
                // mypath: 'http://file.test.runbo360field.com/photos/data/20201130100200119/tiles'
              }}
            /> :
            <PanoramicVideoView
              // refresh={refresh}
              xml={null}
              target="panoVideo"
              initVars={{
                url,
              }}
            />
      }
    </div>

  );
}

const styles: any = {
  container: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  },
}

export default App;
