/**
 * Created by MeePwn
 * https://github.com/maybewaityou
 *
 * description:
 *
 */
import { SelectedTab } from '../tab/data/dataPicture';
import { SelectedVideoTab } from '../tab/data/dataVideo';
import { selectedTextSticker, resetSelectedTextSticker, updateSelectedTextSticker, dynamicVideoAction } from './actions';
import { getQueryString, panoPicture, panoVideo, officialPicture, officialVideo } from '../../utils/functional';
import { processURL } from '../../utils/data';
import { executePanoAction } from '../panoramic/PanoramicView';

export const defaultSquareTitles = ['原图', '无动效', '无配乐'];

const jsonData = {
  scene: [{
    id: 1,
    // url: 'panos/20201120100200012/',
    // url: 'http://file.test.runbo360field.com/photos/data/20200604100200134/tiles',
    url: '',
    sphereurl: '',
    sky: '',
    title: '',
    sticker: [],
    textsticker: [],
    textSticker: [],
    deviceAppCode: "",
  }],
  skyPreview: { sky: '', sphereurl: '' }, // 换天预览模式-暂存数据
  stickerPreview: [], // 贴纸预览模式-暂存数据
  template: { groupId: '', value: '' },
  autorotate: { enabled: 'false', type: '0' },
  music: '',
  bgmItem: {},
  snow: { id: '', type: 'nonesnow' },
  topimg: {
    url: '',
    framerate: 10,
  },
  zfilter: {
    value: 'resetFilter',
    gamma: '',
    brightness: 0,
    contrast: 0,
    exposure: 0,
    hue: 0,
    saturation: 0,
    vibrance: 0,
    sepia: 0,
    swirl: 0,
    pixelate: 0,
    ripple: 0,
    sobel: 0,
    split: 0,
    fquality: 15,
    fblur: 0,
    blur: 0,
    blendmode: 'add',
    blendcolor: '0x0',
    vignettecolor: '0x0',
    mixcolor: '0x0',
  }
};

let videoData = {
  "type": "video",
  "scene": [{
    "id": 1,
    "video": "",
    // video: "",
    "poster": ""
  }],
  music: '',
  bgmItem: {
    url: '',
  },
  template: { groupId: '', value: '' },
  "autorotate": {
    "enabled": "false",
    "type": "0"
  },
  "snow": {
    "type": ""
  },
  "topimg": {
    'id': '',
    "url": "",
    "framerate": 10
  },
  "zfilter": {
    "filter": "0"
  },
  "effect": {
    "vcr": false,
    "fsplit": false,
    "random": false,
    // "fovtype": "0",
    "stereo": false,
    "fblur": false,
    "fhue": false
  },
  "motion": "0",
  "intro": {
    "h": false,
    "v": false,
    "he": false,
    "ve": false
  },
  "crop_intro": {
    "start": {
      "url": "",
      "framerate": "",
    },
    "end": {
      "url": "",
      "framerate": "",
    }
  }
};

// 玩图操作数据，防止污染jsonData
let resetData: any = { ...videoData };

// 视频data
let jsonDataVideo = { ...videoData };

const VPlayer = function () {
  return {
    "jsonData": { ...jsonDataVideo },
    "settings": {
      "editmode": false,
      "e_fsplit": jsonDataVideo.effect.fsplit,
      "e_fblur": jsonDataVideo.effect.fblur,
      "e_vcr": jsonDataVideo.effect.vcr,
      "e_random": jsonDataVideo.effect.random,
      // "e_fovtype": jsonDataVideo.effect.fovtype,
      "e_stereo": jsonDataVideo.effect.stereo,
      "motion": jsonDataVideo.motion,
      "intro": jsonDataVideo.intro,
    },
    "settings_ready": {},
  }
};

(window as any).jsondata = jsonData;
(window as any).VPlayer = VPlayer();


export function getPanoData() {
  // 全景照片panoPicture，全景视频panoVideo
  if (panoPicture() || officialPicture()) {
    return (window as any).jsondata;
  }
  if (panoVideo() || officialVideo()) {
    console.log('=====');

    return (window as any).VPlayer;
  }
  return (window as any).jsondata;
}

export function setPanoData(jsonData: any) {
  // 全景照片panoPicture，全景视频panoVideo
  if (panoPicture() || officialPicture()) {
    (window as any).jsondata = { ...jsonData };
  }
  if (panoVideo() || officialVideo()) {
    jsonDataVideo = { ...jsonData };
    (window as any).VPlayer = VPlayer();
  }
}

/**
 * 作品原因data，用于还原数据
 */
export function setResetData(data: any) {
  resetData = { ...data };
}

/**
 * 玩图操作数据getResetData，‘确定’时更新getResetData，‘取消’时用getResetData更新jsondata，
 * 最后提交getResetData、jsondata数据一直。
 *  */
export function getResetData() {
  return resetData;
}

// 移除模版
export function removeTemplateData(type: string) {
  if (type === 'pic') {
    return jsonData;
  } else {
    videoData.scene[0].poster = getPanoData().jsonData.scene[0].poster;
    return videoData;
  }
}

export function transformToPanoData(config: any) {
  console.log(config);

  // 背景音乐
  if (config.audio) {
    jsonData.music = config.audio;
  }
  // 背景音乐
  if (config.audioConfig) {
    jsonData.bgmItem = {
      title: config.audioConfig.itemTitle,
      value: config.audioConfig.itemID,
    };
  }
  // 巡航
  if (config.cruise && config.cruise !== 'cruiseReset') {
    jsonData.autorotate = {
      type: config.cruise,
      enabled: 'true',
    };
  }
  // 动效
  if (config.dynamicEffect && config.dynamicEffect.snow && config.dynamicEffect.snow.indexOf('nonesnow') === -1) {
    jsonData.snow.type = config.dynamicEffect.snow;
    jsonData.snow.id = dynamicIdByNameMap(jsonData.snow.type.replace('()', ''));
  }
  // 滤镜
  if (config.filter && config.filter.indexOf('resetFilter') === -1) {
    jsonData.zfilter.value = config.filter;
  }
  // 前置贴纸
  if (config.stickersFront) {
    jsonData.topimg.url = config.stickersFront.replace(/%ROOT%\/resource\/img/, 'https://file.runbo360field.com/ads/images/frame');
  }
  // 天空
  if (config.sky && config.sky !== 'sky-reset') {
    jsonData.scene[0].sky = config.sky.replace(/sky-/, '');
    jsonData.scene[0].sphereurl = getQueryString('initvars.mypath');
  }
  // 贴纸转换
  if (config.stickers) {
    jsonData.scene[0].sticker = config.stickers.map((item: any) => {
      const url = item.url || item.value;
      return {
        url: url.replace(/%ROOT%\/resource\/img/, 'https://file.runbo360field.com/ads/images/sticker'),
        scale: 1,
        framerate: 20,
        atv: item.atv,
        ath: item.ath,
      };
    });
  }
  // 文字贴纸转换
  if (config.textTickers) {
    jsonData.scene[0].textSticker = config.textTickers;
  }
  return jsonData;
}



export function editTypetransformToPanoData(config: any) {
  console.log(config);

  // 背景音乐
  if (config.audio) {
    jsonData.music = config.audio;
  }
  // 背景音乐
  if (config.audioConfig) {
    jsonData.bgmItem = {
      title: config.audioConfig.itemTitle,
      value: config.audioConfig.itemID,
    };
  }
  // 巡航
  if (config.cruise && config.cruise !== 'cruiseReset') {
    jsonData.autorotate = {
      type: config.cruise,
      enabled: 'true',
    };
  }
  // 动效
  if (config.dynamicEffect && config.dynamicEffect.snow && config.dynamicEffect.snow.indexOf('nonesnow') === -1) {
    jsonData.snow.type = config.dynamicEffect.snow;
    jsonData.snow.id = dynamicIdByNameMap(jsonData.snow.type.replace('()', ''));
  }
  // 滤镜
  if (config.filter && config.filter.indexOf('resetFilter') === -1) {
    jsonData.zfilter.value = config.filter;
  }
  // 前置贴纸
  if (config.stickersFront) {
    jsonData.topimg.url = config.stickersFront.replace(/%ROOT%\/resource\/img/, 'https://file.runbo360field.com/ads/images/frame');
  }
  // 天空
  if (config.sky && config.sky !== 'sky-reset') {
    jsonData.scene[0].sky = config.sky.replace(/sky-/, '');
    jsonData.scene[0].sphereurl = getQueryString('initvars.mypath');
  }
  // 贴纸转换
  if (config.stickers) {
    jsonData.scene[0].sticker = config.stickers.map((item: any) => {
      const url = item.url || item.value;
      return {
        url: url.replace(/%ROOT%\/resource\/img/, 'https://file.runbo360field.com/ads/images/sticker'),
        scale: 1,
        framerate: 20,
        atv: item.atv,
        ath: item.ath,
      };
    });
  }
  // 文字贴纸转换
  if (config.textTickers) {
    jsonData.scene[0].textSticker = config.textTickers;
  }
  return jsonData;
}

// 为存量数据补充默认数据-拍照
export function addPanoData(config: any) {
  // 天空预览数据
  if (!config.skyPreview) {
    config.skyPreview = {
      sphereurl: '',
      sky: '',
    };
  };
  // 模版默认数据
  if (!config.template) {
    config.template = {
      groupId: '',
      value: '',
    };
  };
  // 贴纸预览数据
  if (!config.stickerPreview) {
    config.stickerPreview = config.scene[0].sticker;
  };
}
/**
 * 作品还原保存设置项
 */
export function resetPanoConfig(dataTree: any) {
  // 还原设置
  const panoData = getPanoData();
  console.log(panoData);

  let dynamicObjList = dataTree[SelectedTab.动效];
  let topImgObj = dataTree[SelectedTab.边框];
  let cruisingObj = dataTree[SelectedTab.巡航];
  let filterObjList = dataTree[SelectedTab.滤镜];
  let skyObjList = dataTree[SelectedTab.换天];
  // 动效
  if (panoData.snow) {
    dynamicObjList = dynamicObjList.map((item: any, index: number) => {
      const itemList = item.list.map((it: any) => ({
        ...it,
        selected: (it.value.replace(/\(\)/, '') === panoData.snow.type) || it.id === panoData.snow.id,
      }));

      const foundItem = itemList.find((it: any) => it.selected === true);
      // 判断是否为无动效
      if (foundItem && defaultSquareTitles.includes(foundItem.title)) {
        return {
          ...item,
          selected: index === 0,
          list: itemList,
        };
      }

      return {
        ...item,
        selected: itemList.find((it: any) => it.selected === true) !== undefined,
        list: itemList,
      };
    });
  }
  // 边框
  if (panoData.topimg.url) {
    topImgObj.list = topImgObj.list.map((item: any) => ({
      ...item,
      selected: item.value.indexOf(panoData.topimg.url) > -1,
    }));
  }
  if (panoData.autorotate.type !== 0 && panoData.autorotate.type !== '0') {
    cruisingObj.list = cruisingObj.list.map((item: any) => ({
      ...item,
      selected: `${panoData.autorotate.type}` === `${item.value}`,
    }));
  }
  // 滤镜
  if (panoData.zfilter.value.toLowerCase() !== 'resetfilter') {
    filterObjList = filterObjList.map((item: any) => {
      const itemList = item.list.map((it: any) => ({
        ...it,
        selected: it.value.indexOf(panoData.zfilter.value) > -1,
      }));
      return {
        ...item,
        selected: itemList.find((it: any) => it.selected === true) !== undefined,
        list: itemList,
      };
    });
  }
  // 天空
  if (panoData.scene[0].sky && panoData.scene[0].sky !== 'sky-reset') {
    skyObjList = skyObjList.map((item: any) => {
      const itemList = item.list.map((it: any) => ({
        ...it,
        selected: it.value === panoData.scene[0].sky,
      }));
      return {
        ...item,
        selected: itemList.find((it: any) => it.selected === true) !== undefined,
        list: itemList,
      };
    });
  }
  return {
    dynamicObjList,
    topImgObj,
    cruisingObj,
    filterObjList,
    skyObjList,
  };
}

/**
 * 作品还原保存设置项-全景照片
 */
export function resetPanoConfigWithSelectedTab(dataTree: any, target: any, selectedTab: SelectedTab) {
  // 还原设置
  const panoData = getPanoData();
  console.log(panoData);

  let cruisingObj = dataTree[SelectedTab.巡航];
  let filterObjList = dataTree[SelectedTab.滤镜];

  // 巡游
  if (panoData.autorotate.type !== 0 && panoData.autorotate.type !== '0') {
    // cruisingObj.list = cruisingObj.list.map((item: any) => ({
    //   ...item,
    //   selected: `${panoData.autorotate.type}` === `${item.value}`,
    // }));
    cruisingObj = cruisingObj.map((item: any) => {
      const itemList = item.list.map((it: any) => ({
        ...it,
        selected: `${panoData.autorotate.type}` === `${it.value}`,
      }));
      return {
        ...item,
        selected: itemList.find((it: any) => it.selected === true) !== undefined,
        list: itemList,
      };
    });
  } else {
    cruisingObj = settingSelected(cruisingObj);
  }

  // 滤镜
  if (panoData.zfilter.value.toLowerCase() !== 'resetfilter') {
    filterObjList = filterObjList.map((item: any) => {
      const itemList = item.list.map((it: any) => ({
        ...it,
        selected: it.value.indexOf(panoData.zfilter.value) > -1,
      }));
      return {
        ...item,
        selected: itemList.find((it: any) => it.selected === true) !== undefined,
        list: itemList,
      };
    });
  } else {
    filterObjList = settingSelected(filterObjList);
  }


  if (selectedTab === SelectedTab.模板) {
    // 模板
    if (panoData.template.value) {
      target = target.map((item: any) => {
        const itemList = item.list.map((it: any) => ({
          ...it,
          selected: `${panoData.template.value}` === `${it.value}`,
        }));
        return {
          ...item,
          selected: itemList.find((it: any) => it.selected === true) !== undefined,
          list: itemList,
        };
      });
      // 模版被删除
      target = usedImageForDelete(target);
    } else {
      target = settingSelected(target);
    }
  }

  if (selectedTab === SelectedTab.动效) {
    if (panoData.snow.type && !panoData.snow.id) {
      panoData.snow.id = dynamicIdByNameMap(panoData.snow.type.replace('()', ''));
    }

    if (target.length > 0) {
      target = target.map((item: any, index: number) => {
        const itemList = item.list.map((it: any) => ({
          ...it,
          selected: (it.value.replace(/\(\)/, '') === panoData.snow.type) || it.id === panoData.snow.id,
        }));

        const foundItem = itemList.find((it: any) => it.selected === true);
        // 判断是否为无动效
        if (foundItem && defaultSquareTitles.includes(foundItem.title)) {
          return {
            ...item,
            selected: index === 0,
            list: itemList,
          };
        }

        return {
          ...item,
          selected: itemList.find((it: any) => it.selected === true) !== undefined,
          list: itemList,
        };
      });
      // 该动效素材url被删除
      target = usedImageForDelete(target);
    }
  }

  if (selectedTab === SelectedTab.边框) {
    if (panoData.topimg.url) {
      target = target.map((item: any, index: number) => {
        const itemList = item.list.map((it: any) => ({
          ...it,
          selected: it.value.indexOf(panoData.topimg.url) > -1 || it.id === panoData.topimg.id,
        }));
        return {
          ...item,
          selected: itemList.find((it: any) => it.selected === true) !== undefined,
          list: itemList,
        };
      });
      // 该边框素材url被删除
      target = usedImageForDelete(target);
    } else {
      target = settingSelected(target);
    }
  }

  if (selectedTab === SelectedTab.换天) {
    if (panoData.scene[0].sky && panoData.scene[0].sky !== 'sky-reset') {
      target = target.map((item: any, index: number) => {
        const itemList = item.list.map((it: any) => ({
          ...it,
          selected: it.value === panoData.scene[0].sky,
        }));

        const foundItem = itemList.find((it: any) => it.selected === true);
        // 判断是否为原图
        if (foundItem && defaultSquareTitles.includes(foundItem.title)) {
          return {
            ...item,
            selected: index === 0,
            list: itemList,
          };
        }

        return {
          ...item,
          selected: itemList.find((it: any) => it.selected === true) !== undefined,
          list: itemList,
        };
      });
      // 该天空素材url被删除
      target = usedImageForDelete(target);
    } else {
      target = settingSelected(target);
    }
  }

  return {
    cruisingObj,
    filterObjList,
    target,
  };
}

/**
 * 作品还原保存设置项-视频
 */
export function resetPanoVideoConfigWithSelectedTab(dataTree: any, target: any, selectedTab: SelectedVideoTab) {
  // 还原设置
  const panoData = getPanoData().jsonData;
  console.log(panoData, '还原设置');

  let visualAngleObj = dataTree[SelectedVideoTab.速剪];
  let dynamicObjList = dataTree[SelectedVideoTab.动效];
  let filterObjList = dataTree[SelectedVideoTab.滤镜];

  // 速剪
  if (panoData.motion !== '0') {
    visualAngleObj = visualAngleObj.map((item: any) => {
      const itemList = item.list.map((it: any) => ({
        ...it,
        selected: `${panoData.motion}` === `${it.value}`,
      }));
      return {
        ...item,
        selected: itemList.find((it: any) => it.selected === true) !== undefined,
        list: itemList,
      };
    });
    // 素材被删除
    visualAngleObj = usedImageForDelete(visualAngleObj);
  } else {
    visualAngleObj = settingSelected(visualAngleObj);
  }

  if (selectedTab === SelectedVideoTab.模板) {
    // 模板
    if (panoData.template.value) {
      target = target.map((item: any) => {
        const itemList = item.list.map((it: any) => ({
          ...it,
          selected: `${panoData.template.value}` === `${it.value}`,
        }));
        return {
          ...item,
          selected: itemList.find((it: any) => it.selected === true) !== undefined,
          list: itemList,
        };
      });
      // 模版被删除
      target = usedImageForDelete(target);
    } else {
      target = settingSelected(target);
    }
  }

  // 动效
  if (selectedTab === SelectedVideoTab.动效) {
    dynamicObjList = [...dynamicObjList, ...target];
    console.log(dynamicObjList, target)
    // topimg
    if (panoData.topimg.url) {
      dynamicObjList = dynamicObjList.map((item: any) => {
        const itemList = item.list.map((it: any) => {
          if (it.type === 'topimg') {
            return {
              ...it,
              selected: `${panoData.topimg.id}` === `${it.value.id}`,
            }
          }
          return it;
        });
        return {
          ...item,
          selected: itemList.find((it: any) => it.selected === true) !== undefined,
          list: itemList,
        };
      });
    } else {
      dynamicObjList = settingSelected(dynamicObjList, 'topimg');
    }

    // effect
    if (Object.values(panoData.effect).includes(true)) {
      Object.keys(panoData.effect).forEach((key: any) => {
        const obj = panoData.effect;
        if (obj[key]) {
          dynamicObjList = dynamicObjList.map((item: any) => {
            const itemList = item.list.map((it: any) => {
              if (it.type === 'effect' && !it.selected) {
                return {
                  ...it,
                  selected: key === it.name,
                }
              }
              return it;
            });
            return {
              ...item,
              selected: itemList.find((it: any) => it.selected === true) !== undefined,
              list: itemList,
            };
          });
        }
      });
    } else {
      dynamicObjList = settingSelected(dynamicObjList, 'effect');
    }

    if (Object.values(panoData.intro).includes(true)) {
      // intro
      Object.keys(panoData.intro).forEach((key: any) => {
        const obj = panoData.intro;
        if (obj[key]) {
          dynamicObjList = dynamicObjList.map((item: any) => {
            const itemList = item.list.map((it: any) => {
              if (it.type === 'intro' && !it.selected) {
                return {
                  ...it,
                  selected: key === it.value,
                }
              }
              return it;
            });
            return {
              ...item,
              selected: itemList.find((it: any) => it.selected === true) !== undefined,
              list: itemList,
            };
          });
        }
      });
    } else {
      dynamicObjList = settingSelected(dynamicObjList, 'intro');
    }

    // crop_intro
    if (panoData.crop_intro.start.url || panoData.crop_intro.end.url) {
      dynamicObjList = dynamicObjList.map((item: any) => {
        const itemList = item.list.map((it: any) => {
          if (it.type === 'crop') {
            return {
              ...it,
              selected: `${panoData.crop_intro.start.url}` === `${it.value.imageurl}` ||
                `${panoData.crop_intro.end.url}` === `${it.value.imageurl}`,
            }
          }
          return it;
        });
        return {
          ...item,
          selected: itemList.find((it: any) => it.selected === true) !== undefined,
          list: itemList,
        };
      });
    } else {
      dynamicObjList = settingSelected(dynamicObjList, 'crop');
    }

    dynamicObjList = settingGroupSelected(dynamicObjList);

  }

  // 滤镜
  if (panoData.zfilter.filter !== '0') {
    filterObjList = filterObjList.map((item: any) => {
      const itemList = item.list.map((it: any) => ({
        ...it,
        selected: `${panoData.zfilter.filter}` === `${it.value}`,
      }));
      return {
        ...item,
        selected: itemList.find((it: any) => it.selected === true) !== undefined,
        list: itemList,
      };
    });
    filterObjList = usedImageForDelete(filterObjList);
  } else {
    filterObjList = settingSelected(filterObjList);
  }

  return {
    visualAngleObj,
    dynamicObjList,
    filterObjList,
    target,
  };
}

/**
 * 重置素材选中状态false
 */
function settingSelected(list: any, type?: any) {
  return list.map((item: any) => {
    const itemList = item.list.map((it: any) => {
      if (!type) {
        return {
          ...it,
          selected: false,
        }
      }
      if (type === it.type) {
        return {
          ...it,
          selected: false,
        }
      }
      return it;
    });
    return {
      ...item,
      list: itemList,
    };
  });
}

/**
 * 设置所有分组只有一个选中状态
 */
function settingGroupSelected(list: any) {
  const obj = list.find((it: any) => it.selected === true);
  return list.map((item: any) => {
    if (item.title === obj.title) {
      item.selected = true;
    } else {
      item.selected = false;
    }
    return item;
  });
}

/**
 * 该素材被删除
 */
function usedImageForDelete(list: any) {
  if (!list.find((it: any) => it.selected === true)) {
    return list.map((item: any, index: any) => {
      return {
        ...item,
        selected: index === 0,
      };
    });
  }
  return list;
}

export function dynamicNameByIdMap(id: string) {
  const map: any = {
    '1001': 'dynamic-1',
    '1002': 'dynamic-2',
    '1003': 'dynamic-3',
    '1004': 'dynamic-4',
    '1005': 'dynamic-5',
    '1006': 'dynamic-6',
    '1007': 'dynamic-7',
    '1008': 'dynamic-8',
    '1009': 'dynamic-9',
    '1010': 'dynamic-10',
    '1012': 'dynamic-12',
    '1014': 'dynamic-14',
  };
  return map[id];
}

export function dynamicIdByNameMap(name: string) {
  const map: any = {
    'dynamic-1': '1001',
    'dynamic-2': '1002',
    'dynamic-3': '1003',
    'dynamic-4': '1004',
    'dynamic-5': '1005',
    'dynamic-6': '1006',
    'dynamic-7': '1007',
    'dynamic-8': '1008',
    'dynamic-9': '1009',
    'dynamic-10': '1010',
    'dynamic-12': '1012',
    'dynamic-14': '1014',
  };
  return map[name];
}

/**
 * 移除贴纸
 */
export function removeTextSticker(id: any) {
  resetSelectedTextSticker();

  const index = getPanoData().scene[0].textSticker.findIndex((item: any) => item.id === id);
  if (index !== -1) {
    getPanoData().scene[0].textSticker.splice(index, 1);
  }
}

/**
 * 保存贴纸
 */
export function saveTextStickerPoint(id: string, text: string, atv: string, ath: string) {
  updateSelectedTextSticker({
    id,
    text,
    atv: `${atv}`,
    ath: `${ath}`,
  });

  const index = getPanoData().scene[0].textSticker.findIndex((item: any) => item.id === selectedTextSticker.id);

  if (index !== -1) getPanoData().scene[0].textSticker.splice(index, 1);
  if (selectedTextSticker.id && selectedTextSticker.text) {
    // selectedConfig.textTickers.push({ ...selectedTextSticker });
    (getPanoData().scene[0].textSticker as any[]).push(selectedTextSticker);
  }
}

(window as any).saveTextStickerPoint = saveTextStickerPoint;
(window as any).removeTextSticker = removeTextSticker;

/**
 * 视频状态
 * @param state videoready 准备就绪， play 播放，paused 暂停，completed 播放结束
 */
function videoState(state: String) {
  console.log(state, '-----videoState')
  if (state === "videoready") {
    // alert(state)
  }
  if (state === "play") {
    // const panoDataStorage: any = getResetData();
    // const data: any = getPanoData();
    // console.log(processURL(panoDataStorage.topimg.url));
    // if (!data.topimg.url) {  
    //   const topimgItem = {
    //     type: 'topimg',
    //     value: {
    //       id: panoDataStorage.topimg.id,
    //       imageurl: processURL(panoDataStorage.topimg.url),
    //       framerate: panoDataStorage.topimg.framerate || 0,
    //     }
    //   }
    //   executePanoAction(dynamicVideoAction(topimgItem));
    // }
  }

}
(window as any).videoState = videoState;

// 全景照片/视频窗口大小
export enum ChangeSize {
  frame = "65",
  tabButton = '134',
  content = '181',
  effect = '213',
  detail = "75",
  push = "155",
  imageButton = "65",
}

export const panoSize: any = {
  frame: ChangeSize.frame,
  tabButton: ChangeSize.tabButton,
  content: ChangeSize.content,
  effect: ChangeSize.effect,
  detail: ChangeSize.detail,
  push: ChangeSize.push,
  imageButton: ChangeSize.imageButton,
};