/**
 * Created by MeePwn
 * https://github.com/maybewaityou
 *
 * description:
 *
 */
import { useState, useEffect } from 'react';
import SizedBox from '../size-box/SizedBox';
import Checkbox from '../checkbox/Checkbox';
import { executePanoAction } from '../panoramic/PanoramicView';
import { onSound3D } from '../panoramic/actions'
import { getQueryString, delay } from "../../utils/functional";
import {
  ACTION_TOGGLE_GYRO_PERMISSION,
  ACTION_SKIN_VIEW_NORMAL,
  ACTION_SKIN_VIEW_FISHEYE,
  ACTION_SKIN_VIEW_LITTLEPLANET,
  ACTION_SKIN_VIEW_STEREOGRAPHIC,
  ACTION_SOUND_PAUSE,
  ACTION_SOUND_PLAY
} from '../panoramic/actions';
import { Radio } from './Radio';
import DialogCopy from '../../components/modal/DialogCopy'
import CruiseVideo from './components/CruiseVideo'
import { miniVideo } from '../../api/NetworkApi'
import Loading from "../loading/Loading";

export function RadioGroupVideo(props: any) {
  const { aplus_queue }: any = window;
  const miniProgram = (window as any).wx.miniProgram;
  const uni = (window as any).uni;
  const userProductId = getQueryString('orderId');
  const [radioState, setRadioState] = useState({
    normal: false,
    fisheye: false,
    littleplanet: false,
    stereographic: false,
    toggleGyro: false,
    cruiseShow: false
  });

  const [checkFlag, setCheckFlag] = useState(false)
  const [modalUrl, setModalUrl]: any = useState('')
  const [cruiseShow, setCruiseShow] = useState(false)
  const [loadingShow, setLoadingShow] = useState(false)
  const [portTitle, setPortTitle] = useState('')
  const [protFlag, setProtFlag] = useState(false)
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    setRadioState({
      normal: false,
      fisheye: false,
      littleplanet: props.selected,
      stereographic: false,
      toggleGyro: false,
    });
  }, [props]);

  // checkbox
  function handleCheckedChange(check: boolean) {
    executePanoAction(ACTION_TOGGLE_GYRO_PERMISSION);
    uma_data_report('视角切换', '陀螺仪')
  }

  function handleSelectedChange(tag: string) {
    if ((radioState as any)[tag] === true) return;
    setCruiseShow(false)
    onSelectedChange(tag);

    setRadioState({
      normal: false,
      fisheye: false,
      littleplanet: false,
      stereographic: false,
      toggleGyro: false,
      cruiseShow: false,
      [tag]: true,
    })
  }

  function onSelectedChange(tag: string) {
    if (tag === 'normal') { // 正常
      executePanoAction(ACTION_SKIN_VIEW_NORMAL);
      uma_data_report('视角切换', '正常')
    } else if (tag === 'fisheye') { // 鱼眼
      executePanoAction(ACTION_SKIN_VIEW_FISHEYE);
      uma_data_report('视角切换', '鱼眼')
    } else if (tag === 'littleplanet') { // 小行星
      executePanoAction(ACTION_SKIN_VIEW_LITTLEPLANET);
      uma_data_report('视角切换', '小行星')
    } else if (tag === 'stereographic') { // 立体
      executePanoAction(ACTION_SKIN_VIEW_STEREOGRAPHIC);
      uma_data_report('视角切换', '立体')
    } else if (tag === 'cruiseShow') { // 陀螺仪{
      setCruiseShow(true)
    }
  }

  // 开启/关闭全景声
  function handleSound(check: boolean) {
    uma_data_report('声音开关', '声音')
    if (check) {
      if (checkFlag) executePanoAction(ACTION_SOUND_PLAY);
      else {
        delay(500).then(onSound3D);
        setCheckFlag(true)
      }
    } else {
      console.log('false ---');
      executePanoAction(ACTION_SOUND_PAUSE);
    }
  }

  // 友盟数据上报
  function uma_data_report(type: string, title?: string) {
    aplus_queue.push({
      action: "aplus.record",
      arguments: ['video_click', 'CLK', {
        'type': type,
        'title': title ? title : ''
      }],
    });
  }

  // 用户点击取消 不保存
  function modalHandleCancel() {
    setModalOpen(false)
  }

  function devicePano3() {
    return (
      <div style={styles.container}>
        {/* icon修改之前的正常  */}
        {/* <Radio
        text="正常"
        selected={radioState.normal}
        tag="normal"
        icon="img/miniProgramIcon/ic-normal-unchecked.png"
        highlightIcon="img/miniProgramIcon/ic-normal.png"
        onSelectedChange={handleSelectedChange}
      />
      <SizedBox style={styles.sizedBox} /> */}
        <Checkbox
          text="声音"
          style={styles.soundIcon}
          // 选中
          icon="img/miniProgramIcon/icon_sound_play.png"
          // 未选中
          highlightIcon="img/miniProgramIcon/icon_sound_pause.png"
          onCheckedChange={handleSound}
        />
        <SizedBox style={styles.sizedBox} />
        {/* icon修改之后的icon 图标是鱼眼的 */}
        <Radio
          text="正常"
          selected={radioState.normal}
          tag="normal"
          icon="img/miniProgramIcon/ic-fisheye-unchecked.png"
          highlightIcon="img/miniProgramIcon/ic-fisheye.png"
          onSelectedChange={handleSelectedChange}
        />
        <SizedBox style={styles.sizedBox} />
        {/* <Radio
        text="鱼眼"
        selected={radioState.fisheye}
        tag="fisheye"
        icon="img/miniProgramIcon/ic-fisheye-unchecked.png"
        highlightIcon="img/miniProgramIcon/ic-fisheye.png"
        onSelectedChange={handleSelectedChange}
      />
      <SizedBox style={styles.sizedBox} /> */}
        <Radio
          text="星球"
          tag="littleplanet"
          selected={radioState.littleplanet}
          icon="img/miniProgramIcon/ic-smallplanet-unchecked.png"
          highlightIcon="img/miniProgramIcon/ic-smallplanet.png"
          onSelectedChange={handleSelectedChange}
        />
        <SizedBox style={styles.sizedBox} />
        <Radio
          text="立体"
          tag="stereographic"
          selected={radioState.stereographic}
          icon="img/radio/ic-stereographic-unchecked.png"
          highlightIcon="img/radio/ic-stereographic.png"
          onSelectedChange={handleSelectedChange}
        />
        <SizedBox style={styles.sizedBox} />
        {/* <Checkbox
        text="陀螺仪"
        icon="img/miniProgramIcon/ic-gyro-unchecked.png"
        highlightIcon="img/miniProgramIcon/ic-gyro.png"
        onCheckedChange={handleCheckedChange}
      /> */}
        <Radio
          text="特效"
          tag="cruiseShow"
          selected={radioState.cruiseShow}
          icon="img/radio/ic-stereographic-unchecked.png"
          highlightIcon="img/radio/ic-stereographic.png"
          onSelectedChange={handleSelectedChange}
        />

      </div>
    )
  }

  // 轮循请求
  const miniVideoFn = (params: any) => {
    let time: any = null;
    setLoadingShow(true)
    miniVideo(params).then((res: any) => {
      if (res[1].errMsg.indexOf('当前访问人数较多') > 0) {
        setLoadingShow(false)
        setProtFlag(true)
        setPortTitle('当前访问人数较多，请稍后重试!');
      } else if (res[1].fileUrl != '') {
        setModalUrl(res[1].fileUrl)
        setLoadingShow(false)
        setLoadingShow(false)
        // 视频录制完成之后 模态框展示渲染完成视频
        setModalOpen(true)
      } else {
        // 轮询请求
        time = setInterval(() => {
          clearInterval(time);
          miniVideoFn(params)
        }, 3000);
      }
    })
  }

  // 生成视频之后 弹窗展示 点击确定
  function modalHandleOk() {
    setLoadingShow(true)
    // 视频生成后 弹窗展示生成后的视频 并通过用户点击是否保存来判断是否进行保存视频
    const saveFlag = (/iPad|iPhone|iPod|Mac/.test(navigator.userAgent)) ? 'ios' : 'android'
    miniProgram.postMessage({
      data: [{ type: saveFlag == 'ios' ? "videoUrlIOS" : "videoUrlAndroid", url: modalUrl }],
    });
    uma_data_report('视频保存相册', '')
    setLoadingShow(false)
    uni.reLaunch({ url: "/pages/index/index?page=page2" });
  }

  function trajectoryMovement(item: any) {
    const params = {
      user_product_id: userProductId,
      command: item.command,
    }
    miniVideoFn(params)
  }

  return (
    <>
      <Loading show={loadingShow} />
      {/* 点击特效 弹出特效列表 */}
      {
        cruiseShow && <CruiseVideo
          trajectoryMovementFn={trajectoryMovement}
          show={cruiseShow}
        />
      }
      {
        modalOpen && modalUrl && <DialogCopy
          src={modalUrl}
          show={modalOpen}
          onConfirm={modalHandleOk}
          onCancel={modalHandleCancel}
          positiveTitle="视频保存到相册"
          negativeTitle="取消保存"
        />
      }
      {
        devicePano3()
      }
    </>
  );
}

const styles: any = {
  container: {
    textAlign: "center"
  },
  sizedBox: {
    height: 5,
  },
};

export default RadioGroupVideo;
