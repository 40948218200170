/**
 * Created by MeePwn
 * https://github.com/maybewaityou
 *
 * description:
 * 默认直接展示
 */
import { Base64 } from 'js-base64';
import { RenderStrategy, RenderType } from './RenderStrategy';
import { dynamicLoadPanoXML, reloadPanoVideoXML, executePanoAction } from '../../../panoramic/PanoramicView';
import { delay, panoPicture, panoVideo, getQueryString, shouldShowPush } from '../../../../utils/functional';
import { fetchDataConfigById, fetchTemplateGroup, fetchDataRecommendById } from '../../../../api/NetworkApi';
import { getResetData, removeTemplateData, setResetData, getPanoData, setPanoData } from '../../../panoramic/data';
import { processURL } from '../../../../utils/data';

export class DefaultRenderStrategy extends RenderStrategy {
  protected renderType: RenderType = RenderType.DEFAULT;
  public apply(params?: any) {
    const { tipControl, hooks: { setPushData, setOrderData } } = params;
    if (panoPicture()) {
      delay(200).then(dynamicLoadPanoXML);
      const orderId = getQueryString('orderId');
      if (orderId) {
        fetchDataConfigById(orderId).then(async ([error, result]) => {
          if (result.repeat_status_code === '01') {
            tipControl.showFullTips("重拍中，请稍后查看");
          }
          setResetData(getPanoData());
          setOrderData(result);
        }).catch((error) => {
          console.log('error :', error);
        });
      }
    }
    if (panoVideo()) {
      delay(200).then(reloadPanoVideoXML);
      // 订单查询视频封面
      const orderId = getQueryString('orderId');
      if (orderId) {
        tipControl.showLoading();
        fetchDataConfigById(orderId).then(async ([error, result]) => {
          tipControl.dismissLoading();
          if (result.repeat_status_code === '01') {
            // tipControl.showTextLoading("重拍中请稍后", 'img/tips/wait.png');
            tipControl.showFullTips("重拍中，请稍后查看");
          }
          setOrderData(result);
          // 玩图特效
          getPanoData().jsonData.scene[0].poster = processURL(result.poster_url || result.thumbnail_url);
          setResetData(getPanoData().jsonData);
        }).catch((error) => {
          console.log(error);
        });
      }

      // 推荐景区查询视频封面
      const resourceId = getQueryString('resourceId');
      if (resourceId) {
        fetchDataRecommendById(resourceId).then(async ([error, result]) => {
          // 封面
          getPanoData().jsonData.scene[0].poster = processURL(result.poster_url || result.thumbnail_url);
          setResetData(getPanoData().jsonData);
        }).catch((error) => {
          console.log(error);
        });
      }
    }
    // 拍照推送-随机模版
    if (shouldShowPush()) {
      const typeCode = getQueryString('typeCode');
      tipControl.showLoading();
      fetchTemplateGroup(typeCode).then(([error, result]) => {
        tipControl.dismissLoading();
        if (error) return tipControl.showToast(error);
        let arr = result.result_list;
        const randomList = [];
        if (arr.length > 5) {
          for (let index = 0; index < 5; index++) {
            const obj = arr[Math.floor(Math.random() * arr.length)]; // 随机模版
            arr = arr.filter((item: any) => item.id !== obj.id);
            randomList.push(obj);
          }
        } else {
          randomList.push(...arr);
        }

        console.log(randomList, '拍照推送-随机模版');
        // 设置随机模版数据
        setPushData(randomList || []);
      });
    }
  }
}
