/**
 * Created by MeePwn
 * https://github.com/maybewaityou
 *
 * description:
 *
 */
import MenuLayout from '../../menu/MenuLayout';
import ButtonFooter from '../../footer/ButtonFooter';
import TextFooter from '../../footer/TextFooter';
import PushFooter from '../../footer/PushFooter';
import NothingFooter from '../../footer/NothingFooter';
import Toast from '../../toast/Toast';
import FullTips from '../../toast/FullTips';
import Loading from '../../loading/Loading';
import TextLoading from '../../loading/TextLoading';
import MusicControl from '../../music-control/MusicControl';
import BackgroundMusicService from '../../audio/BackgroundMusicService';
import { useController } from './controller/MPFloatViewController';
import { FooterType, FloatViewConfig, } from './index.d';
import { panoPicture, getQueryString } from '../../../utils/functional';
import { useEffect, useState } from 'react';
// import Payment from '../../../components/Payment/index'
import { fetchDataConfigById } from '../../../api/NetworkApi'
export function MPFloatView(props: FloatViewConfig) {
  const [type_code, setTypeCode] = useState('01')
  // 订单路径如果支付 paymentFlag 为 true 未支付为false  作品分享图片生成海报的时候 路径无法控制 所以判断是否为空 如果为空则为true
  // let payment_flag = getQueryString('paymentFlag') == 'false' ? false : true;
  const {
    footerType,
    showLoading,
    toast,
    toastImage,
    showTextLoading,
    textLoadingData,
    showButton,
    showNavigationButton,
    showAcceptPhotoButton,
    hideMenu,
    cleanScreen,
    showQuitCleanButton,
    bgmItem,
    bgmPlaying,
    panoConfig,
    details,
    pushData,
    orderData,
    showShotCause,
    tipControl,
    menuHeight,
    fullTips,
    showContribution,
    getVr,
    setMenuHeight,
    handleClick,
    handleNavigationButtonClick,
    handleAcceptPhotoButtonClick,
    handleBGMItemClick,
    handleBGMControlClick,
    handleCleanScreen,
    handleQuitCleanClick,
    handleShot,
    handleContribution,
    setBGMItem,
  } = useController(props);


  return (
    <>
      {
        // payment_flag ? (
        <div style={styles.container} >
          <Toast title={toast} image={toastImage} />
          <Loading show={showLoading} />
          <TextLoading show={showTextLoading} data={textLoadingData} />
          <FullTips title={fullTips} />
          <BackgroundMusicService item={bgmItem} />
          {
            cleanScreen && !showQuitCleanButton ? <></>
              :
              !hideMenu && !showQuitCleanButton &&
              <>
                {
                  // 玩图不显示menu-玩图入口/原片作品入口
                  (footerType === FooterType.tab || (footerType === FooterType.button && !showButton)) ? <></> :
                    <MenuLayout
                      showNavigationButton={showNavigationButton}
                      showAcceptPhotoButton={showAcceptPhotoButton}
                      menuHeight={menuHeight}
                      orderData={orderData}
                      onShot={handleShot}
                      handleContribution={handleContribution}
                      onCleanScreen={handleCleanScreen}
                    />
                }
                <MusicControl
                  title={bgmItem.title}
                  playing={bgmPlaying}
                  item={bgmItem}
                  onClick={handleBGMControlClick}
                />
              </>
          }
          {
            cleanScreen && !showQuitCleanButton ? <></>
              :
              // 默认
              footerType === FooterType.nothing ? <NothingFooter setMenuHeight={setMenuHeight} /> :
                // 详情
                footerType === FooterType.text ?
                  <TextFooter
                    data={details}
                  /> :
                  // 推送 (清屏时不显示)
                  !showQuitCleanButton && footerType === FooterType.push ?
                    <PushFooter
                      data={pushData}
                      orderData={orderData}
                      tipControl={tipControl}
                      setBGMItem={setBGMItem}
                      showShotCause={showShotCause}
                      hideCause={handleShot}
                      setMenuHeight={setMenuHeight}
                    />
                    :
                    // 玩图
                    !getVr && (footerType === FooterType.button || footerType === FooterType.tab || footerType === FooterType.push) ?
                      <ButtonFooter
                        showButton={showButton}
                        showShotCause={showShotCause}
                        showNavigationButton={showNavigationButton}
                        showAcceptPhotoButton={showAcceptPhotoButton}
                        showQuitCleanButton={showQuitCleanButton}
                        tipControl={tipControl}
                        panoConfig={panoConfig}
                        orderData={orderData}
                        onBGMItemClick={handleBGMItemClick}
                        onQuitClick={handleQuitCleanClick}
                        onNavigationButtonClick={handleNavigationButtonClick}
                        onAcceptPhotoButtonClick={handleAcceptPhotoButtonClick}
                        onClick={handleClick}
                        setBGMItem={setBGMItem}
                        hideCause={handleShot}
                        handleContribution={handleContribution}
                        showContribution={showContribution}
                        setMenuHeight={setMenuHeight}
                      /> :
                      <></>
          }
        </div >
        // ) : <Payment type_code={type_code}></Payment>
      }
    </>
  );
}

const styles = {
  container: {
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
  },
};

export default MPFloatView;
