import React, { useState } from 'react'
import Modal from '../../modal/Modal';

function Cruise(props: any) {
    console.log('props :', props);

    const { cruiseData, show } = props;
    const [newCruiseData, setNewCruiseData] = useState([...cruiseData.list, {
        "title": "旅行风笔刷",
        "image": "img/cruising/cruising_1.png",
        "selected": false,
        "value": 6
    }, {
        "title": "变速聚焦",
        "image": "img/cruising/cruising_1.png",
        "selected": false,
        "value": 7
    }])
    const handleSelectedChange = (item: any) => {
        props.trajectoryMovementFn && props.trajectoryMovementFn(item)
    }

    return (
        show &&
        <Modal>
            <div style={styles.container}>
                {
                    newCruiseData.map((item: any) => {
                        return (
                            <div style={styles.divBox} onClick={() => { handleSelectedChange(item) }}>
                                <img style={styles.image} src={item.image} alt="" />
                                <span>{item.title}</span>
                            </div>
                        )
                    })
                }
            </div>
        </Modal>
    )
}
const styles: any = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        position: 'absolute',
        height: 300,
        bottom: 150,
        right: 55,
        zIndex: 1001,
        overflow: 'scroll'
        // backgroundColor: 'rgba(0,0,0,0.3)'
    },
    divBox: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: 12,
        color: 'white',
        margin: '0 0 10px 0'
    },
    image: {
        width: 30,
        height: 30
    }
};
export default Cruise